import { alertController, toastController } from '@ionic/vue'
import { add2MostradorStorage } from '@/utilities/storage/storage-utilities-main'
/*
    import { globalFilters } from '@/utilities/filters'
    const {Entero} = globalFilters
*/

const consola = function () { void (0) } //consola

const openToast = async function (head, msg, tiempo) {
    const toast = await toastController
        .create({
            header: head,
            message: msg,
            duration: tiempo === undefined ? 3000 : tiempo,
            position: 'middle',
            buttons: [
                {
                    side: 'end',
                    text: 'Ok'
                }
            ]
        })
    await toast.present()
}

const openAlertaBinariaInput = async function (head, msg, bNo, bSi, mis_inputs, callback) {
    const alerta = await alertController
        .create({
            header: head,
            message: msg,
            mode: "md",
            inputs: mis_inputs,
            buttons: [
                {
                    text: bNo === undefined ? 'Si' : bNo,
                    handler: () => { callback(false, null) }
                },
                {
                    text: bSi === undefined ? 'Si' : bSi,
                    handler: (data) => { callback(true, data) }
                }
            ]
        })
    return alerta.present()
}


const openAlertaEsUbicacionPrincipal = async function (dx) {
    window.fake_console(dx)
    const head = `Existen ubicaciones...`
    const msg = `Ya hay ubicaciones informadas.\n Es esta la nueva ubicación principal? `
    const alerta = await alertController
        .create({
            header: head,
            message: msg,
            mode: "md",
            backdropDismiss: false,
            buttons: [
                {
                    text: 'NO', role: 'cancel',
                    handler: () => { window.miconsola('desestimamos') }
                },
                {
                    text: 'SI', role: 'confirm',
                    handler: () => { window.miconsola('en el handler') }
                }
            ]
        })
    await alerta.present()
    const { role } = await alerta.onDidDismiss()
    window.fake_console({ role })
    return role == 'cancel' ? false : true
}


const openAlertaUnidadesCarrito = async function (cliente, pdv, sku_data) {
    const { sku, dispo, unimed, stoalm, hay_stock, uniser = 1 } = sku_data
    let disponible = Math.min(dispo, stoalm)
    const head = `Albaran Mostrador`

    const msg1 = `
            <b>${uniser != 1 ? '<h4>Se vende en  multiplos de ' + uniser.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</b></h4>    
            Introduce las unidades ( ${unimed}) necesarias.<br/>
            Las que excedan el disponible<b> (${disponible.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}) </b>las incorporaremos como una linea adicional para que puedas pedirlas al fabricante u otro pdv. `
    const msg2 = `
        <b>${uniser != 1 ? '<h4>Se vende en  multiplos de ' + uniser.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</b></h4>
        Introduce las unidades (${unimed}) necesarias.<br/>
            Las incorporaremos como una linea para pedir al fabricante u otro pdv. `

    const alerta = await alertController
        .create({
            header: head,
            subHeader: `${sku}`,
            message: hay_stock ? msg1 : msg2,
            mode: "md",
            cssClass: 'custom-ptl-alert',
            backdropDismiss: false,
            inputs: [
                {
                    value: "Unidades Albaran",
                    disabled: true,
                    cssClass: 'etiqueta lx'
                },
                {
                    name: 'uds_alb',
                    type: 'number',
                    id: 'uds_albaranar',
                    cssClass: 'grande prioritaria',
                    tabindex: 0,
                    min: 0,
                    max: Math.min(dispo, stoalm),
                },
                {
                    value: '',
                    id: 'control_error_uniser',
                    disabled: true,
                    cssClass: 'etiqueta-error lx'
                }
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                        consola('Alert canceled')
                    }
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: (data) => {
                        const valor = parseFloat(data.uds_alb)
                        window.fake_console(valor, uniser, valor % uniser)
                        if (valor % uniser === 0) {
                            return true
                        }
                        else {
                            const ux = uniser.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            control_error.value = `Unidades deben ser multiplo de ${ux}`
                            return false
                        }
                    }
                }
            ]
        })
    await alerta.present()

    let control_error = document.getElementById('control_error_uniser')
    const { data, role } = await alerta.onDidDismiss()

    if (role == 'confirm') {
        const valor = parseFloat(data.values.uds_alb)
        sku_data.uds_alb = valor
        sku_data.tipo = hay_stock === true ? 'stock' : 'pedido'

        if (cliente != undefined) {
            await add2MostradorStorage(cliente, pdv, sku_data)
        } else {
            await add2MostradorStorage(parseInt('9' + pdv), pdv, sku_data)
        }
        return true
    }
    else { return undefined }
}


const openAlertaSiNo = async function (cliente, head, msg) {
    const alerta = await alertController
        .create({
            header: head,
            subHeader: `Cliente : ${cliente}`,
            message: msg,
            mode: "md",
            cssClass: 'custom-ptl-alert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'CANCELAR',
                    role: 'cancel'
                },
                {
                    text: 'SI',
                    role: 'confirm'
                }
            ]
        })
    await alerta.present()
    const { role } = await alerta.onDidDismiss()
    if (role == 'confirm') {
        return true
    } else {
        return undefined
    }
}


const finalizarCompra = async function () {
    const alerta = await alertController
        .create({
            header: 'Creacion de pedido cliente',
            message: 'El pedido ha sido creado correctamente. Recibirá un email con el pedido adjunto',
            mode: "md",
            cssClass: 'custom-ptl-alert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    role: 'confirm'
                }
            ]
        })
    await alerta.present()

    await alerta.onDidDismiss()
    return true
}



const OpenAlertaPDV = async function (pdvs, actual) {

    const mis_inputs = pdvs.map((v) => {
        return { 'label': v.v, 'type': 'radio', 'value': v.k, 'checked': v.k == actual }
    })
    const head = `Selecciona PDV`
    const msg = `Selecciona uno de tus PDV`
    const alerta = await alertController
        .create({
            header: head,
            message: msg,
            mode: "md",
            cssClass: 'custom-ptl-alert',

            backdropDismiss: false,
            inputs: mis_inputs,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                        consola('Alert canceled')
                    },
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        consola('Alert confirmed')
                    }
                }
            ]
        })
    await alerta.present()

    const { data, role } = await alerta.onDidDismiss()
    consola(data)
    if (role == 'confirm') {
        return data
    }
    else {
        return false
    }
}



const openAlertaV2 = async function (head, msg) {
    const alerta = await alertController
        .create({
            header: head,
            message: msg,
            mode: "md",
            cssClass: 'alerta-estandar',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Si',
                    handler: () => {
                        window.fake_console('en el handler')
                    }
                }
            ]
        })
    await alerta.present()
    const { data } = await alerta.onDidDismiss()
    window.fake_console(data)
    return
}

const openAlertaBinaria = async function (head, msg, bNo, bSi) {
    const alerta = await alertController
        .create({
            header: head,
            message: msg,
            mode: "md",
            backdropDismiss: false,
            buttons: [
                {
                    text: bNo === undefined ? 'No' : bNo,
                    role: 'cancel'
                },
                {
                    text: bSi === undefined ? 'Si' : bSi,
                    role: 'confirm',
                },
            ]
        })
    await alerta.present()
    const { role } = await alerta.onDidDismiss()
    if (role == 'confirm') { return true }
    else { return false }
}


export {
    openToast, openAlertaBinaria,
    openAlertaBinariaInput,
    openAlertaUnidadesCarrito,
    openAlertaSiNo, 
    /** Revisadas */
    openAlertaV2, openAlertaEsUbicacionPrincipal,
    OpenAlertaPDV,
    finalizarCompra
}

