
<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="derecha">
                    Mobiges<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content v-if="!showme">
            <ion-row class="spin ion-justify-content-center " v-if="!showme">
                <ion-col v-if="!showme" class="spin ion-align-self-center" size="48">
                    <ion-spinner v-if="!showme"></ion-spinner>
                </ion-col>
            </ion-row>
        </ion-content>

        <ion-content v-else class="ion-padding">
            <ion-row>
                <ion-col size="9">
                    <ion-button class="alternativa impresion" fill="solid" expand="block" color="tertiary"
                        @click="BusquedaManual()">
                        <i class="fas fa-shapr fa-search blanca">
                            <ion-text class="ion-text-wrap en-botton">
                                Buscar producto
                            </ion-text>
                        </i>
                    </ion-button>
                </ion-col>
                <ion-col size="30">
                    <ion-button expand="block" fill="outline" size="large" class="trigger_scan" @click="selecionarCliente()"
                        v-if="cliente">
                        <ion-title>Carrito en curso de
                            <br />
                            <ion-text class="ion-text-wrap estrecho">
                                <b>{{ nomcli }}</b><br />( {{ cliente }} )
                            </ion-text>
                        </ion-title>
                    </ion-button>
                    <ion-button expand="block" fill="outline" size="large" class="trigger_scan" @click="selecionarCliente()"
                        v-else>
                        <ion-title>Seleccionar cliente
                            <br />
                        </ion-title>
                    </ion-button>
                </ion-col>

                <ion-col size="9" v-if="cliente">
                    <ion-button class="alternativa impresion" fill="solid" expand="block" color="darksuccess"
                        @click="BusquedaFavoritos()">
                        <i class="fas fa-sharp fa-star blanca">
                            <ion-text class="ion-text-wrap en-botton">
                                Habitual Cliente
                            </ion-text>
                        </i>
                    </ion-button>
                </ion-col>
            </ion-row>

            <ion-row v-if="riesgo_excedido">
                <h5>Riesgo excedido!</h5>
            </ion-row>

            <ion-row>
                <ion-list class="ion-no-padding" v-if="datos && datos.length > 0">
                    <ion-list-header class="head" style="width:100% !important;">
                        <ion-row>
                            <ion-col size="48">
                                <ion-text class="fake-titulo"> Articulos lista </ion-text>
                            </ion-col>
                        </ion-row>
                    </ion-list-header>

                    <ion-item-sliding v-for="(product, index) in datos" v-bind:key="`item-${index}`">
                        <ion-item v-bind:key="`item-${index}`" class="ion-no-padding ion-no-margin">
                            <ion-row>

                                <ion-col size-offset="1" size="30" class="ion-no-margin  ion-no-padding">
                                    <h3 @click="ElementoStockSeleccionado(product)">
                                        {{ $filters.showSku(product.sku) }}
                                    </h3>
                                    <h4 @click="ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap>
                                        {{ product.shortDescription }}
                                    </h4>
                                </ion-col>

                                <!-- RESULTADOS DESDE EL STOCK -->
                                <ion-col size="20" class="ion-no-margin  ion-no-padding">
                                    <h5 @click="ElementoStockSeleccionado(product)" class="full-h5">
                                        <span class="lote">Suc: {{ product.codsuc }}</span>
                                    </h5>
                                    <h5 @click="ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap
                                        v-if="product.lote != null">
                                        <b><span class="lote">Lote : {{ product.lote }}</span></b>
                                    </h5>
                                </ion-col>

                                <ion-col size="11" class="ion-no-margin  ion-no-padding">
                                    <ion-text @click="ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                        <span class="stock">
                                            <b>{{ $filters.Entero(product.uds_alb) }}</b>
                                        </span>
                                    </ion-text>
                                    <ion-text @click="ElementoStockSeleccionado(product)" class="mini-lista" text-wrap>
                                        {{ product.unimed }} {{ product.uniser || 1 != 1 ? '/' + product.uniser : '' }}
                                    </ion-text>
                                </ion-col>

                                <ion-col class="ion-no-margin  ion-no-padding ion-text-end implin" v-if="product.precio">
                                    <ion-text @click="ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                        <span class="stock">
                                            <b>{{ 'Total: ' + $filters.sDecimal(product.implin) + '€' }}</b>
                                        </span>
                                    </ion-text>

                                    <h5 @click="ElementoStockSeleccionado(product)" class="full-h5">
                                        <span class="lote">{{ 'Precio: ' + $filters.sDecimal(product.precio) + '€' }}</span>
                                    </h5>

                                </ion-col>
                            </ion-row>
                        </ion-item>

                        <ion-item-options side="end">
                            <ion-item-option color="danger" @click="borrar(product)">
                                <ion-icon slot="bottom" class="muy-grande" :icon="trashOutline"></ion-icon>
                                <span class="boton-opciones">Borrar</span>
                            </ion-item-option>
                        </ion-item-options>

                    </ion-item-sliding>
                </ion-list>

                <ion-item v-else>
                    <ion-row no-padding no-margin no-lines>
                        <ion-col class="centro" v-if="cliente">
                            <ion-label>No hay articulos en el carrito del cliente</ion-label>
                        </ion-col>
                        <ion-col class="centro" v-else>
                            <ion-label>Carrito vacio</ion-label>
                        </ion-col>
                    </ion-row>
                </ion-item>
            </ion-row>
        </ion-content>

        <ion-footer v-if="subtotal">
            <ion-item> <b>Subtotal: </b> {{ $filters.sDecimal(subtotal) + '€' }}</ion-item>
        </ion-footer>

        <ion-footer>
            <ion-toolbar color="primary">

                <ion-buttons slot="start" class="completo" color="darkdanger">
                    <ion-button class="completo" expand="block" fill="solid" color="darkdanger" id="borrar_lista"
                        size="normal" @click="DelAll()" :disabled="!datos || datos.length == 0">
                        <b>Borrar</b>
                    </ion-button>
                </ion-buttons>

                <ion-buttons slot="end" class="completo">
                    <ion-button class="completo" expand="block" fill="solid" id="traspasar_lista" size="normal"
                        @click="UpdateMysql()" :disabled="(!datos || datos.length == 0)">
                        <b>Guardar</b>
                    </ion-button>
                </ion-buttons>

                <ion-buttons slot="end" class="completo">
                    <ion-button class="completo" expand="block" fill="solid" id="traspasar_lista" size="normal"
                        @click="crearPedido()" :disabled="(!datos || datos.length == 0 || !cliente || riesgo_excedido)">
                        <b>Pedido G2K</b>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script>
import { reject } from 'underscore'
import { defineComponent } from 'vue'

import { saveMostradorStorage, loadMostradorStorage, delMostradorStorage } from '@/utilities/storage/storage-utilities-main'
import { openAlertaV2, openAlertaSiNo, OpenAlertaPDV } from '@/utilities/services/alert-utilities'
import { homeSharp, trashOutline, star, search, documentTextSharp, checkbox, squareOutline } from 'ionicons/icons'
import { get_pdv_data, recuperaPrecios, recuperaDatosCliente } from '@/utilities/services/carrito_services'


export default defineComponent({
    name: "ListaMostradorPage",
    props: ['cliente', 'nomcli', 'origen', 'pdv_act'],
    data() {
        return {
            trashOutline, star, search, documentTextSharp, checkbox, squareOutline, homeSharp,
            datos: [],
            showme: false,
            pdv: undefined,
            pdv_actual: undefined,
            subtotal: undefined,
            cliente_data: undefined,
            riesgo_excedido: false
        }
    },
    async created() {
        this.pdv = this.$scope_pdv
    },
    async mounted() {
        await this.inicializar()
        console.log('showme true')
        this.showme = true
    },
    methods: {
        async recuperar_bbdd() {
            var pdv, codcli

            if (this.cliente) {
                [pdv, codcli] = this.cliente.split('.')
            } else {
                [pdv, codcli] = [this.pdv_actual, parseInt('9' + this.pdv_actual)]
            }

            const lista_bbdd = await this.$http.post(`/get_carrito/${pdv}/${codcli}`)
            const dat = lista_bbdd.data
            return (dat && dat.length > 0) ? dat : undefined
        },
        selecionarCliente() {
            this.$router.replace({ 'name': 'lista_clientes', params: { origen: 'carrito' } })
        },
        async recuperarPDV() {
            if (this.cliente) {
                this.pdv_actual = this.cliente.slice(0, 3)
            } else if (this.origen == 'home') {
                if (this.pdv == '000') {
                    this.pdv = this.$pdv_list
                } else if ((this.pdv.match(/;/g) || []).length) {
                    this.pdv = this.$pdv_list.filter(p => this.pdv.split(';').includes(p['k']))
                } else {
                    this.pdv = this.$pdv_list.filter(p => { return p['k'] == this.pdv })
                }

                if (this.pdv.length > 1) {
                    this.pdv_actual = (await OpenAlertaPDV(this.pdv, this.pdv[0])).values
                } else {
                    this.pdv_actual = this.pdv
                }
            } else if (this.pdv_act) {
                this.pdv_actual = this.pdv_act
            }
        },
        async inicializar() {
            await this.recuperarPDV()

            if (!this.pdv_actual) {
                this.$router.replace({ 'name': 'home' })
            }

            await get_pdv_data(this)

            var stg = undefined
            console.log('tengo cliente?', this.cliente)
            if (this.cliente) {
                console.log('cargamos mostrador cliente', this.cliente, this.pdv_actual)
                stg = await loadMostradorStorage(this.cliente, this.pdv_actual)
                this.cliente_data = await recuperaDatosCliente(this)
            } else {
                console.log('cargamos mostrador pdv', parseInt('9' + this.pdv_actual), this.pdv_actual)
                stg = await loadMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual)
            }

            let lista_previa = await this.recuperar_bbdd()

            if (lista_previa) {
                let confirmacion

                if (this.cliente) {
                    confirmacion = await openAlertaSiNo(this.cliente, 'Lista previa existente!', `
                            Hay una lista previa del cliente ${this.nomcli}.<br/>\n
                            Si quieres recuperarla pulsa "SI", sino "CANCELAR"`)
                } else {
                    confirmacion = await openAlertaSiNo(`PDV ${this.pdv_actual}`, 'Lista previa existente!', `
                            Hay una lista previa.<br/>\n
                            Si quieres recuperarla pulsa "SI", sino "CANCELAR"`)
                }

                if (confirmacion) {
                    lista_previa = lista_previa.reduce((m, valor) => {
                        const { sku, codemp, codsuc, lote, tipo } = valor
                        const clau = `${sku}.${codemp}.${codsuc}.${!lote ? 'N' : lote}.${!tipo ? 'pedido' : tipo}`
                        valor['fila'] = clau
                        m.push(valor)
                        return m
                    }, [])

                    if (this.cliente) {
                        await saveMostradorStorage(this.cliente, this.pdv_actual, lista_previa)
                    } else {
                        await saveMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual, lista_previa)
                    }
                    this.datos = lista_previa
                }
                else {
                    let pdv, codcli
                    if (this.cliente) {
                        [pdv, codcli] = this.cliente.split('.')
                    } else {
                        [pdv, codcli] = [this.pdv_actual, parseInt('9' + this.pdv_actual)]
                    }
                    console.log('inicializar pdv codcli', pdv, codcli)
                    await this.$http.post(`/set_carrito/${pdv}/${codcli}`, { carrito: [] })
                    this.datos = stg
                }
            }
            else if (stg) {
                this.datos = stg
            }

            if (this.datos && this.cliente) {
                await this.RecuperarPrecios()
                await this.CalculaTotales()
            }

            try {
                this.datos.sort((a, b) => {
                    const a1 = a.done == true ? 'Z' : 'A';
                    const b1 = b.done == true ? 'Z' : 'A';
                    const a2 = (a.ubi || 'NOUBI').toString()
                    const b2 = (b.ubi || 'NOUBI').toString()
                    // primero ordenamos por pendientes / hecho, después por zona ascendente
                    return a1.localeCompare(b1) || a2.localeCompare(b2)
                })
            }
            catch {
                return
            }
        },
        async borrar(item) {
            const clau = `${item.sku}.${this.pdv_actual}.${item.codsuc}.${!item.lote ? 'N' : item.lote}.${!item.tipo ? 'pedido' : item.tipo}`
            console.log(clau)
            console.log(this.datos)
            const quedan = reject(this.datos, (e) => e.fila == clau)
            this.datos = quedan

            if (this.cliente) {
                console.log('guardamos en ', this.cliente, this.pdv_actual, this.datos)
                await saveMostradorStorage(this.cliente, this.pdv_actual, this.datos)
                await this.CalculaTotales()
            } else {
                await saveMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual, this.datos)
            }
            if (this.datos.length == 0) {
                if (this.cliente) {
                    await delMostradorStorage(this.cliente, this.pdv_actual)
                    await this.CalculaTotales()
                } else {
                    await delMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual)
                }
            }
        },

        async ElementoStockSeleccionado(item) {
            this.$router.replace({ 'name': 'detalle_producto', params: { sku: item.sku, origen: `/carrito-mostrador/${this.cliente}` } })
        },

        async CalculaTotales() {
            console.log('aver el riesgo', this.cliente_data)
            this.subtotal = this.datos.reduce((m, v) => {
                m += v.implin
                return m
            }, 0)

            if (this.subtotal > this.cliente_data.riesgo_cliente[0].dis) {
                this.riesgo_excedido = true
            } else {
                this.riesgo_excedido = false
            }
        },

        async DelAll() {
            if (this.cliente) {
                await delMostradorStorage(this.cliente, this.pdv_actual)
            } else {
                await delMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual)
            }
            this.datos = []
        },

        async BusquedaManual(termino_entrada, tipo_busqueda) {
            const { nomcli, cliente, pdv_actual } = this
            const origen = `/carrito`
            let parametros
            if (termino_entrada) {
                parametros = { pdv_actual, nomcli, cliente, origen, termino_entrada, tipo_busqueda }
            }
            else {
                parametros = { pdv_actual, nomcli, cliente, origen }
            }
            this.$router.replace({ 'name': 'busqueda_mostrador', params: parametros })
        },

        async BusquedaFavoritos() {
            const { nomcli, cliente } = this
            const origen = `/carrito-mostrador/${cliente}`
            let parametros = { nomcli, cliente, origen }
            this.$router.replace({ 'name': 'favoritos_mostrador', params: parametros })
        },

        async RecuperarPrecios() {
            await recuperaPrecios(this)
        },

        async UpdateMysql() {
            window.fake_console('this.datos', this.datos)
            let self = this
            let codcli

            if (this.cliente) {
                codcli = this.cliente.split('.')[1]
            } else {
                codcli = parseInt('9' + this.pdv_actual)
            }

            const payload = this.datos.reduce((memo, val) => {
                const { sku, codsuc, uds_alb, lote, tipo } = val
                memo.push({ codsuc, codmar: sku.slice(0, 3), artpro: sku.slice(4), uds_alb, lote, tipo })
                return memo
            }, [])


            /** Grabamos el carrito  */
            try {
                const feedback = await self.$http.post(`/set_carrito/${this.pdv_actual}/${codcli}`, { carrito: payload })
                if (feedback.data.status == 1) {
                    await openAlertaV2('Lista transferida', `
                                        Has grabado la lista del cliente ${this.pdv_actual + '.' + codcli}.<br/>\n
                                        Borramos memoria y te dirigimos a la home`)
                    await this.DelAll()
                    this.$router.replace('/home')
                }
                else { throw 'feedback negativo' }
            }
            catch (ex) {
                window.fake_console('ERROR ', ex)
            }

        },
        async crearPedido() {
            console.log('creamos pedido g2k')
            console.log('en el carrito: ', this.datos)

            //const self = this
            //const codcli = this.cliente.split('.')[1]

            const articulos = this.datos.reduce((memo, val) => {
                const { sku, uds_alb, precio, implin, unipre } = val
                memo.push({ codmar: sku.slice(0, 3), artpro: sku.slice(4), unidad: uds_alb, precio: precio, implin: implin, unipre })
                return memo
            }, [])

            console.log('articulos', articulos)

            this.$router.replace({
                'name': 'crear-pedido', params: {
                    'cliente': this.cliente, 'nomcli': this.nomcli, 'pdv_act': this.pdv_actual,
                    'articulos': JSON.stringify(articulos), 'cliente_data': JSON.stringify(this.cliente_data),
                    'origen': `/carrito-mostrador/${this.cliente}`
                }
            })
        },
        async checkLinea(item) {

            if (item.block !== true) {
                this.showme = false
                item.done = !item.done
                const clau = `${item.sku}.${this.pdv_actual}.${item.codsuc}.${!item.lote ? 'N' : item.lote}.${!item.tipo ? 'pedido' : item.tipo}`
                let self = this
                const quedan = reject(this.datos, (e) => e.fila == clau)

                if (item.done === true) {
                    quedan.push(item)
                }
                else {
                    quedan.unshift(item)
                }
                this.datos = quedan

                await saveMostradorStorage(self.cliente, this.pdv_actual, self.datos)
                if (this.datos.length == 0) {
                    if (this.cliente) {
                        await delMostradorStorage(this.cliente, this.pdv_actual)
                    } else {
                        await delMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual)
                    }
                }

                this.showme = true
            }
        },
        async update_data(item) {
            const clau = `${item.sku}.${this.pdv_actual}.${item.codsuc}.${!item.lote ? 'N' : item.lote}.${!item.tipo ? 'pedido' : item.tipo}`
            let self = this
            const quedan = reject(this.datos, (e) => e.fila == clau)
            this.datos = quedan

            if (self.cliente) {
                await saveMostradorStorage(this.cliente, this.pdv_actual, this.datos)
            } else {
                await saveMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual, this.datos)
            }
            if (this.datos.length == 0) {
                if (this.cliente) {
                    await delMostradorStorage(this.cliente, this.pdv_actual)
                    await this.CalculaTotales()
                } else {
                    await delMostradorStorage(parseInt('9' + this.pdv_actual), this.pdv_actual)
                }
            }
            return
        }
    },
    computed: {
        largo: function () {
            return Object.keys(this.datos).length
        }
    },

})
</script>

<style scoped  lang="scss">
@import "@/theme/footer-styles.scss";
@import "@/theme/seleccion-pedido-styles.scss";

ion-title {
    padding-right: 4.5rem !important;
}

span.boton-opciones {
    font-size: larger !important;
    padding-bottom: 0.3rem !important;
    font-weight: bold !important;
}

ion-badge {
    width: 100% !important;
}

ion-row.altos {
    height: 3rem !important;

}

ion-text.en-botton {
    font-family: 'Roboto';
    font-size: 0.9rem !important;
    color: var(--ion-color-blanco) !important;
    text-transform: none !important;
    line-height: 0.4rem !important;
}

i.fas.blanca,
i.far.blanca {
    font-size: 2rem !important;
    color: var(--ion-color-blanco) !important;
    line-height: 1rem !important;
}

ion-row {
    max-width: 600px !important;
    padding: 0.3rem 0.2rem !important;
}

ion-item {
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
}

ion-text.fake-titulo {
    color: var(--ion-color-primary) !important;
}

span.oscuro {
    color: var(--ion-color-medium) !important;
    padding-left: 0.3rem !important;
}

span.primario {
    color: var(--ion-color-primary) !important;
    padding-left: 0.3rem !important;
}

span.peque {
    font-size: smaller !important;
    padding-right: 0.3rem !important;
}

.precio {
    font-size: small !important;
    width: 100% !important;
    float: right !important;
    /*color : var(--ion-color-medium) !important; */
    font-weight: 400 !important;
}

.implin {
    font-size: large !important;
    width: 100% !important;
    float: right !important;
    /*color : var(--ion-color-medium) !important; */
    font-weight: 400 !important;
}

i.fas.tiempo,
i.far.tiempo {
    color: red;
    font-weight: 600 important;
    font-size: 1.8rem !important;
    padding-right: 0.4rem !important
}

ion-item.iniciado {
    border-right: 10px solid !important;
    border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
}

ion-icon.crossdock {
    font-size: xx-large !important;
    font-weight: 1000 !important;

}

.margen-down {
    margin-bottom: 0.3rem !important;
}</style>