
import eventBus from "@/event-bus"
import { saveMainStorage } from '@/utilities/storage/storage-utilities-main'


const SaveTokenMySQL = async function (self, user, tk) {
    console.log('guardamos en Mysql el token', user, tk)
    var supRequest = await self.$http.post(`/register_token`, {
        usuario: user,
        token: tk
    })
    var res = supRequest.data

    if (res && res[0].status == 'OK') {
        return true
    } else {
        return false
    }
}

const LoginWithGoogle = async function (self) {
    try {
        await self.$firebase.auth().setPersistence(self.$firebase.auth.Auth.Persistence.LOCAL)
        const provider = new self.$firebase.auth.GoogleAuthProvider()
        provider.setCustomParameters({ prompt: 'select_account' })

        await self.$firebase.auth().signInWithPopup(provider)
        const t = await self.$firebase.auth().currentUser.getIdToken(true)

        console.log('currentUser', self.$firebase.auth().currentUser)

        const user = self.$firebase.auth().currentUser.email.substring(0, self.$firebase.auth().currentUser.email.indexOf('@'))
        const user_name = self.$firebase.auth().currentUser.displayName

        // Recuperamos los datos del usuario
        var supRequest = await self.$http.post(`/my_profile_data`, { email: user})
        var user_data = supRequest.data
        console.log('login_services user_data', user_data)

        if (user_data != []) {

            supRequest = await self.$http.post(`/get_pdvs`)
            user_data[0]['pdv_list'] = JSON.parse(supRequest.data[0].datos)
            
            console.log(user_data)
            console.log('vamos al emit')

            const res = await SaveTokenMySQL(self, user, t.substr(0, 35))

            if (res) {
                await saveMainStorage(t, user, user_name)
                eventBus().emitter.emit('user-logged', user_data[0])
            } else {
                console.log('No hemos guardado el token')
            }
        }
    }
    catch (error) {
        window.fake_console('EN EL ERROR DE LOGIN')
        window.fake_console(error)
        console.log(error)
        console.log('Login 73 (Error) : ', JSON.stringify(error))
    }
}

export {
    LoginWithGoogle
}