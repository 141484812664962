<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title class="derecha">
        Mobiges<br />
        <ion-note>
          <small>by GES</small>
        </ion-note>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-row class="header">
    <ion-col class="list-title">
      <ion-button color="primary" @click="goBack()">
        <ion-icon :icon="arrowBackOutline" padding></ion-icon>
      </ion-button>
      <h3>{{ titulo }} pendientes</h3>
    </ion-col>
  </ion-row>

  <ion-content>
    <ion-card class="card-data-details" v-if="datos">
      <ion-card-header>
        <div>
          <ion-icon :icon="fileTrayFullOutline"></ion-icon>
          <div class="customer-name"><b>Datos generales</b></div>
        </div>
      </ion-card-header>
      <ion-card-content>
        <ion-grid class="details-data">
          <ion-row>
            <ion-col>
              <span>Pedido</span><br>{{ numDoc }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <span>Fecha</span><br>{{ formatDate(datos.head.dt_o) }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <span>Estado</span><br>
              <p :class="datos.head.e == 'P' ? 'rojo' : ''">
                {{ datos.head.e == "P" ? "Pendiente" : "Cerrado" }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row v-if="datos.head.pc">
            <ion-col>
              <span>Pedido cliente</span><br>{{ datos.head.pc }}
            </ion-col>
          </ion-row>
          <ion-row v-if="datos.head.de">
            <ion-col>
              <span>Direccion</span><br>{{ datos.head.de }}<br />{{ datos.head.le }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>


    <ion-card class="pedidos-details">
      <ion-card-header>
        <div>
          <ion-icon :icon="listOutline" />

          <div v-if="tipo == 'pedido'" class="customer-name"><b>Detalle del Pedido</b></div>
          <div v-if="tipo == 'oferta'" class="customer-name"><b>Detalle de la Oferta</b></div>
        </div>
      </ion-card-header>
      <ion-card-content v-if="datos">
        <ion-row>
          <ion-col size="25" ><b>Articulo</b></ion-col>
          <ion-col class="ion-text-center"><b>Cantidad</b></ion-col>
          <ion-col class="ion-text-center"><b>Dto</b></ion-col>
          <ion-col class="ion-text-right"><b>Importe</b></ion-col>
        </ion-row>

        <ion-row class="line-detail" style="width: 100%;" v-for="linea in datos.detail" :key="linea"
          :class="linea.e == 'P' ? 'pendiente item item-block item-md' : 'listado item-block item-md'">

          <ion-col size="25" class="desc-detail">
            <span>{{ linea.ds }}</span><br>
            {{ linea["sku"] }}
          </ion-col>
          <ion-col class="ion-text-center">
            <strong>
              <span>{{ linea.un }}</span>
              <br>
              <span v-if="linea.un - (linea.u_ser || 0) != 0 && linea.e != 'C'" class="rojo">
                ({{ linea.un - (linea.u_ser || 0) }}<sub>P</sub>)
              </span>
              <span v-if="linea.un - (linea.u_ser || 0) != 0 && linea.e == 'C'" class="rojo"
                style="text-decoration:line-through;">
                ({{ linea.un - (linea.u_ser || 0) }}<sub>P</sub>)
              </span>
            </strong>
          </ion-col>
          <ion-col class="ion-text-center">
            <span class="dispo" style="color: green !important">
              {{ descuentos(linea) }}
            </span>
          </ion-col>
          <ion-col class="ion-text-right importe">
            <!--<span>{{ $filters.formatNumber(linea.vnet) }}</span><br>-->
            <span>{{ linea.vnet }}</span>
            <!--<div class="sub-cantidad">{{ $filters.formatNumber(linea.pr) }}/ {{ linea.up }}</div>-->
            <div class="sub-cantidad">{{ linea.pr }}/ {{ linea.up }}</div>
            <div v-if="linea.vcar">
              <h5><i>
                  <!--<span>Rae: {{ $filters.formatNumber(linea.vcar / 1) }}</span>-->
                  <span>Rae: {{ linea.vcar / 1 }}</span>
                </i></h5>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="totales" style="width: 100%" v-if="datos.head.vcar">
          <ion-col size="25" offset="15" class="ion-text-right">
            <h2><strong>Ecotasa</strong></h2>
          </ion-col>
          <ion-col class="ion-text-right">
            <h2>
              <strong>
                <!--<span v-html="$filters.formatNumber(datos.head.vcar)"></span>-->
                <span v-html="datos.head.vcar"></span>
              </strong>
            </h2>
          </ion-col>
        </ion-row>
        <ion-row :class="datos.head.vcar ? 'sinpadding' : ''" v-if="datos.head.vbru">
          <ion-col size="25" offset="15" class="ion-text-right">
            <h2><strong>Subtotal</strong></h2>
          </ion-col>
          <ion-col class="ion-text-right">
            <h2>
              <strong>
                <!--<span v-html="$filters.formatNumber(datos.head.vbru)"> </span>-->
                <span v-html="datos.head.vbru"> </span>
              </strong>
            </h2>
          </ion-col>
        </ion-row>
        <ion-row v-if="datos.head.vimp">
          <ion-col size="25" offset="15" class="ion-text-right">
            <h2><strong>Impuestos</strong></h2>
          </ion-col>
          <ion-col class="ion-text-right">
            <h2>
              <strong>
                <!--<span v-html="$filters.formatNumber(datos.head.vimp)"> </span>-->
                <span v-html="datos.head.vimp"> </span>
              </strong>
            </h2>
          </ion-col>
        </ion-row>
        <ion-row v-if="datos.head.valb">
          <ion-col size="25" offset="15" class="ion-text-right">
            <h2><strong>Total</strong></h2>
          </ion-col>
          <ion-col class="ion-text-right">
            <h2>
              <strong>
                <!--<span v-html="$filters.formatNumber(datos.head.valb)"></span>-->
                <span v-html="datos.head.valb"></span>
              </strong>
            </h2>
          </ion-col>
        </ion-row>

      </ion-card-content>
    </ion-card>

  </ion-content>
</template>
<script>

import { arrowBackOutline, fileTrayFullOutline, listOutline } from "ionicons/icons";

export default {
  component: {},
  name: "DetalleDocumento",
  data: () => ({
    arrowBackOutline,
    fileTrayFullOutline,
    listOutline,
    numDoc: "",
    datos: null
  }),
  props: {
    tipo: undefined,
    titulo: undefined,
    cliente: undefined,
    codemp: undefined,
    codsuc: undefined,
    numped: undefined,
    origen: undefined,
    datos_tabla: undefined
  },
  mounted() {
    this.numDoc = this.codemp + '.' + this.codsuc + '.' + this.numped
    console.log('origen', this.origen)
    console.log('tipo', this.tipo)
    console.log('titulo', this.titulo)
    this.cargaPedido()
  },
  methods: {
    goBack() {
      this.$router.replace({ 'name': this.origen, params: { cliente: this.cliente, tipo: this.titulo, origen: this.cliente ? 'home_cliente' : 'home' } })
    },
    async cargaPedido() {
      const res = await this.$http.post(`/${this.tipo}/${this.numDoc}`)
      const data = res.data
      this.datos = data
    },
    descuentos(el) {
      return el.dto && el.dto != "Neto"
        ? (parseFloat(el["dto"]).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + "%")
        : el["dto"]
    },
    formatDate(d) {
      d = typeof d == "string" ? d : d.toString()
      return d.slice(6, 8) + "/" + d.slice(4, 6) + "/" + d.slice(2, 4)
    }
  }
}
</script>
  
<style scoped>
.pedidos-details ion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-title h3 {
  width: 100%;
}

ion-card-header {
  color: #203360;
  font-size: 24px;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

ion-card-header div ion-icon {
  font-size: 32px;
  position: absolute;
  margin-top: -3px
}

ion-card-header>ion-icon {
  cursor: pointer;
}

.customer-name {
  margin-left: 40px;
  display: inline-block;
}

.details-data {
  width: 100% !important;
  color: #203360;
}

.details-data span {
  font-size: 12px;
  color: #737373;
}

.card-data-details {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 30px;
}

.line-detail:nth-child(odd) {
  background: #f7f7f7 !important;
}

ion-col {
  font-size: 12px;
}

ion-col span {
  color: #203360;
  font-size: 14px;
}

.rojo {
  color: #fc0f1d !important;
}

.dispo {
  font-size: 14px;
}

.show-mobile {
  display: none;
}

.mobile-no-show {
  display: inline;
}

h5 span {
  font-size: 12px;
}

.details ion-grid {
  width: 100%;
}

@media (max-width: 900px) {
  .mobile-no-show {
    display: none;
  }

  .show-mobile {
    display: inline;
  }

  ion-grid {
    width: 100%;
    padding: 10px 0 0 0;
    margin: 0 auto;
    font-size: 14px;
  }

  ion-col {
    font-size: 10px;
  }

  .card-data-details {
    margin: 5px 0 10px 0px;
  }

  .pedidos-details {
    margin: 0px 0 10px 0px;
  }

  ion-card-header {
    color: #203360;
    font-size: 18px;
    font-weight: bolder;
  }

  ion-card-content {
    padding: 0 15px 15px
  }

  ion-card-header div ion-icon {
    font-size: 25px;
  }

  ion-col span {
    color: #203360;
    font-size: 12px;
  }

  .dispo {
    font-size: 11px;
  }

  .details ion-grid ion-col {
    padding: 0;
  }

  .details-data {
    padding-top: 0px;
  }

  .details-data ion-col {
    padding-bottom: 0;
  }

  .details-data span {
    display: inline;
    padding-right: 5px;
  }

  .details-data .rojo {
    display: inline;
  }

  .details-data br {
    display: none;
  }

  .importe {
    white-space: nowrap;
  }

  .sub-cantidad {
    position: absolute;
    right: 0;
  }

  .card-content-md h5 {
    margin-top: 14px;
  }
}
</style>
  