<template>
  <ion-card id="ventames" @click="routerTo()">
    <ion-card-header>
      <b color="dark">Plan y situación de ventas</b>
    </ion-card-header>
    <ion-card-content v-if="ventas">
      <ion-grid class="situacion-ventas" style="font-size: smaller" text-right>
        <ion-row>
          <ion-col size="8" offset="20"><strong>Venta</strong></ion-col>
          <ion-col size="8"><strong>Margen</strong></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="20"><span><strong>Plan mes</strong></span></ion-col>
          <ion-col size="8">
            {{ formatNumberNoCurrency(ventas.plmv) }}
          </ion-col>
          <ion-col size="8">
            {{ formatNumberNoCurrency(ventas.plmm) }}
          </ion-col>
          <ion-col size="8">
            <b :class="(ventas.plmm / ventas.plmv) > 0 ? 'green-color' : 'red-color'">
              {{ formatNumberNoCurrency((ventas.plmm / ventas.plmv) * 100) }}%
            </b>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="20">
            <span><strong>Real Actual</strong></span>
          </ion-col>
          <ion-col size="8">
            {{ formatNumberNoCurrency(ventas.mv) }}
          </ion-col>
          <ion-col size="8">
            {{ formatNumberNoCurrency(ventas.mm) }}
          </ion-col>
          <ion-col size="8">
            <b :class="(ventas.mm / ventas.mv) > 0 ? 'green-color' : 'red-color'">
              {{ formatNumberNoCurrency(((ventas.mm / ventas.mv) * 100)) }}%
            </b>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="20">
            <span><strong>Desviacion actual</strong></span>
          </ion-col>
          <ion-col size="8">
            <b :class="(ventas.mv - ventas.pldv) > 0 ? 'green-color' : 'red-color'">
              {{ formatNumberNoCurrency(ventas.mv - ventas.pldv) }}
            </b>
          </ion-col>
          <ion-col size="8">
            <b :class="(ventas.mm - ventas.pldm) > 0 ? 'green-color' : 'red-color'">
              {{ formatNumberNoCurrency(ventas.mm - ventas.pldm) }}
            </b>
          </ion-col>

        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
export default {
  component: {},
  name: "SituacionVentasCard",
  props: {
    title: String,
    user: {},
    ventas: {}
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat().format(num);
    },
    formatNumberNoCurrency: function (num) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(num);
    },
    routerTo() {
      return//this.$router.replace({ 'name': 'newrac', params: { datos: {}, origen: 'home' } })
    }
  },
  mounted() { }
}
</script>

<style>
.desktop-no-show {
  display: none;
}
</style>

<style scoped>
.situacion-ventas {
  width: 100% !important;
}

.green-text {
  position: absolute;
  top: 39px;
  left: 16px;
  text-align: left !important;
}


ion-card-header {
  /*--color: #203360 !important;*/
  color: white;
  font-size: 14px;
  background-color: #33a2df;
}

ion-row {
  margin: 0;
}

.title-card {
  color: #203360 !important;
  font-weight: bold;
}

.green-color {
  font-size: 16px;
  color: #4a9d77 !important;
  display: block;
  margin-bottom: 10px;
}

.red-color {
  font-size: 16px;
  color: #fc0f1d !important;
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .mobile-no-show {
    display: none;
  }

  .green-text {
    position: relative;
    top: 0;
    left: 0;
    text-align: right !important;
  }

  .title-card {
    font-size: 12px;
    white-space: nowrap;
  }

  .column-table {
    padding: 0;
    margin: 0;
  }

  .column-table ion-col {
    padding: 0;
    margin: 0;
  }

  .column-title {
    padding-bottom: 5px;
  }

  .resumen-ventas {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: right;
    font-size: 12px;
  }

  #ventames {
    padding: 0px;
    margin: 0 10px;
  }

  #ventames ion-card-content {
    padding: 0 0 10px 0;
  }


  #ventames ion-card-header {
    padding: 10px 18px 4px;
  }



  .border-col strong {
    margin-bottom: 2px;
    font-size: 12px;
    padding-right: 7px;
  }

  .green-color {
    margin-bottom: 2px;
    font-size: 12px;
    white-space: nowrap;
    padding-right: 7px;
  }

  .red-color {
    margin-bottom: 2px;
    font-size: 14px;
    white-space: nowrap;
    padding-right: 7px;
  }

  .card-content-md {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .clientes-vencidos {
    margin-left: 10px;
  }

  .vencido-col {
    min-height: 90px;
    pading: 4px 20px 0px 40px
  }

  .icon-person {
    font-size: 28px;
  }

  .vencido-col ion-icon {
    top: 16px;
  }
}
</style>
