<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title class="derecha">
                    Mobiges<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
            </ion-toolbar>
        </ion-header>


        <ion-content class="ion-padding" v-if="showme">
            <ion-row v-if="direcciones_envio">

                <ion-item>
                    Dirección de envío:
                    <ion-select v-model="direccion_envio" placeholder="Seleccione la dirección" cancelText="Cancelar"
                        :change="setDireccion()">
                        <ion-select-option v-for="(item, index) in direcciones_envio"
                            :key="'item-' + index + '-' + item.tdir" :value="index">
                            {{ item.domenv }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>

            </ion-row>

            <ion-row v-if="obras_clientes">
                <ion-item>
                    Obra:
                    <ion-select v-model="obra_cliente" placeholder="Seleccione la Obra" cancelText="Cancelar">
                        <ion-select-option v-for="(item, index) in obras_clientes" :key="'item-' + index + '-' + item"
                            :value="item.domenv">
                            {{ item }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-row>

            <ion-row>
                Entrega total?
                <ion-item>
                    <IonToggle v-model="enttot" @ionChange="setEnttot" />
                </ion-item>
            </ion-row>

            <ion-row>
                Pedido cliente:
                <ion-item>
                    <IonInput maxlength="15" v-model="cabecera.pedcli" :placeholder="'Pedido cliente'" />
                </ion-item>
            </ion-row>

            <ion-row>
                Fecha entrega:
                <ion-item>
                    <ion-datetime :min="currentDate()" :value="currentDate()" preferWheel="false" />
                </ion-item>
            </ion-row>

            <ion-row>
                Teléfono:
                <ion-item>
                    <IonInput maxlength="9" v-model="cabecera.telefo" type="tel" inputmode="decimal" :placeholder="'Telefono'" />
                </ion-item>
            </ion-row>

            <ion-row>
                Observaciones:
                <ion-item>
                    <IonInput maxlength="80" v-model="cabecera.obsent" :placeholder="'Observaciones pedido'" />
                </ion-item>
            </ion-row>
        </ion-content>

        <ion-content v-if="showme">
            <ion-grid>
                <ion-row class="detalleHeader">
                    <ion-col size="20">Referencia</ion-col>
                    <ion-col size="10">Precio</ion-col>
                    <ion-col size="8">Cantidad</ion-col>
                    <ion-col size="10">Importe</ion-col>
                </ion-row>
                <ion-row v-for="(item, index) in detalle" :key="index">
                    <ion-col size="20">{{ item.codmar + '.' + item.artpro }}</ion-col>
                    <ion-col size="10">{{ $filters.sDecimal(item.precio).toString() }}</ion-col>
                    <ion-col size="8">{{ $filters.sDecimal(item.unidad).toString() }}</ion-col>
                    <ion-col size="10">{{ $filters.sDecimal(item.implin).toString() }}</ion-col>
                </ion-row>
            </ion-grid>

        </ion-content>

        <ion-footer>
            <ion-toolbar color="primary">
                <ion-buttons slot="start" class="completo" color="darkdanger">
                    <ion-button class="completo" expand="block" fill="solid" color="darkdanger" id="borrar_lista"
                        size="normal" @click="goBack()">
                        <b>Atrás</b>
                    </ion-button>
                </ion-buttons>
                
                <ion-buttons slot="end" class="completo">
                    <ion-button class="completo" expand="block" fill="solid" id="traspasar_lista" size="normal"
                        @click="crearPedido()" :disabled="!cabecera_completa">
                        <b>Crear pedido</b>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script>

import { defineComponent } from 'vue'
import { IonDatetime, IonInput, IonToggle } from '@ionic/vue'

import { homeSharp } from 'ionicons/icons'
import { recuperaDatosCliente, crearPedidoG2k } from '@/utilities/services/carrito_services'
import { delMostradorStorage } from '@/utilities/storage/storage-utilities-main'
import { finalizarCompra } from '@/utilities/services/alert-utilities'



export default defineComponent({
    name: "ListaMostradorPage",
    props: ['cliente', 'nomcli', 'pdv_act', 'articulos', 'cliente_data', 'origen'],
    components: { IonDatetime, IonToggle, IonInput },
    data() {
        return {
            homeSharp,            
            cabecera: {
                pdv: this.pdv_act,
                cliente: this.cliente.split('.')[1],
                fecha_entrega: this.currentDate().slice(0, 10),
                pedcli: undefined,
                nomenv: undefined,
                domenv: undefined,
                pobenv: undefined,
                cposta: undefined,
                telefo: undefined,
                obra: undefined,
                enttot: 'N',
                obsent: undefined
            },
            detalle: [],
            direcciones_envio: undefined,
            obras: undefined,

            direccion_envio: undefined,
            obra_cliente: undefined,

            showme: false
        }
    },
    async created() {
        this.pdv = this.$scope_pdv
    },
    async mounted() {
        await this.inicializar()
        this.showme = true
        console.log(this.direcciones_envio)
    },
    methods: {
        async inicializar() {

            await this.getDatosCliente()

            this.detalle = JSON.parse(this.articulos).reduce((m, v) => {
                m.push({
                    codmar: v.codmar,
                    artpro: v.artpro,
                    unidad: v.unidad,
                    precio: v.precio,
                    unipre: v.unipre,
                    implin: v.implin
                })
                return m
            }, [])
            this.showme = true
        },
        goBack() {
            this.$router.replace({ 'name': 'carrito', params: { 'cliente': this.cliente, 'nomcli': this.nomcli, 'pdv_act': this.pdv_act } })
        },
        async getDatosCliente() {
            this.datos_cliente = await recuperaDatosCliente(this)
            this.direcciones_envio = JSON.parse(this.datos_cliente.direcciones_envio[0].datos)
            this.obras_clientes = this.datos_cliente.obras_clientes
        },
        setEnttot() {
            if (this.enttot) {
                this.cabecera.enttot = 'S'
            } else {
                this.cabecera.enttot = 'N'
            }
        },
        setDireccion() {
            if (this.direccion_envio != undefined) {                
                this.cabecera.cposta = this.direcciones_envio[this.direccion_envio].cpenv
                this.cabecera.domenv = this.direcciones_envio[this.direccion_envio].domenv
                this.cabecera.nomenv = this.direcciones_envio[this.direccion_envio].nomenv
                this.cabecera.pobenv = this.direcciones_envio[this.direccion_envio].pobenv
            }
        },
        async crearPedido() {            
            let [res, comment] = await crearPedidoG2k(this)
            if (res == 'OK') {
                console.log('Pedido creado!')
                await finalizarCompra()                

                await delMostradorStorage(this.cliente, this.pdv_act)
                this.$router.replace({ 'name': 'home' })
            } else {
                console.log(comment)
            }

        },
        currentDate() {
            const current = new Date()
            const date = `${current.getFullYear()}-${("0" + (current.getMonth() + 1)).slice(-2)}-${("0" + (current.getDate())).slice(-2)}T00:00:00`
            return date
        }
    },
    computed: {
        largo: function () {
            return Object.keys(this.datos).length
        },
        cabecera_completa: function () {
            return this.cabecera.pdv && this.cabecera.pedcli && this.cabecera.fecha_entrega && this.cabecera.nomenv && this.cabecera.domenv && this.cabecera.pobenv && this.cabecera.cposta && this.cabecera.enttot
        }
    }

})
</script>

<style scoped  lang="scss">
@import "@/theme/footer-styles.scss";
@import "@/theme/seleccion-pedido-styles.scss";

ion-title {
    padding-right: 4.5rem !important;
}

span.boton-opciones {
    font-size: larger !important;
    padding-bottom: 0.3rem !important;
    font-weight: bold !important;
}

ion-badge {
    width: 100% !important;
}

ion-row.altos {
    height: 3rem !important;

}

ion-text.en-botton {
    font-family: 'Roboto';
    font-size: 0.9rem !important;
    color: var(--ion-color-blanco) !important;
    text-transform: none !important;
    line-height: 0.4rem !important;
}

i.fas.blanca,
i.far.blanca {
    font-size: 2rem !important;
    color: var(--ion-color-blanco) !important;
    line-height: 1rem !important;
}

ion-row {
    max-width: 600px !important;
    padding: 0.3rem 0.2rem !important;
}

ion-item {
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
}

ion-text.fake-titulo {
    color: var(--ion-color-primary) !important;
}

span.oscuro {
    color: var(--ion-color-medium) !important;
    padding-left: 0.3rem !important;
}

span.primario {
    color: var(--ion-color-primary) !important;
    padding-left: 0.3rem !important;
}

span.peque {
    font-size: smaller !important;
    padding-right: 0.3rem !important;
}

i.fas.tiempo,
i.far.tiempo {
    color: red;
    font-weight: 600 important;
    font-size: 1.8rem !important;
    padding-right: 0.4rem !important
}

ion-item.iniciado {
    border-right: 10px solid !important;
    border-image: linear-gradient(to bottom, white, var(--ion-color-darkdanger), white) 2;
}

ion-icon.crossdock {
    font-size: xx-large !important;
    font-weight: 1000 !important;

}

.margen-down {
    margin-bottom: 0.3rem !important;
}

.detalleHeader {
    background-color: #33a2df;
    color: white;
}
</style>