<template>
    <ion-page>
        <ion-content key="`content:${qt}`">
            <ion-row class="google ion-align-items-center ion-justify-content-center">
                <ion-button v-on:click="loginWithGoogle" fill="outline" size="large" color="primary" class="login">
                    <ion-icon slot="start" icon="assets/google.svg" />
                    Login
                </ion-button>
            </ion-row>
            <ion-row class="google ion-align-items-start ion-justify-content-center">
                <ion-img src="img/icons/android-chrome-192x192.png" />
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { LoginWithGoogle } from '@/utilities/services/login_services';

export default defineComponent({
    name: "Login",
    methods: {
        async loginWithGoogle() {
            window.fake_console('en el login de google (pagina login)')
            const self = this
            await LoginWithGoogle(self)
        }
    }
})
</script>

<style scoped>
ion-row.google {
    height: 35%;
}
</style>