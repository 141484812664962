const debugar = true
//const URL = 'https://beta.ges.services'
//const URL = 'http://localhost:3000'
const URL = 'https://mobiges.ges.services'

const arranque = '/home'
console.log('en la app nueva')

export const myURL = URL
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { IonicVue } from '@ionic/vue'
import { Device } from "@capacitor/device"
//import { openToast } from '@/utilities/services/alert-utilities'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import eventBus from "@/event-bus"
import { globalFilters } from './utilities/filters'
import { loadStorage/*, limpiarMemoria*/ } from '@/utilities/storage/storage-utilities-main'
import { Camera } from '@capacitor/camera'
import PrimeVue from 'primevue/config'

/*
  Importamos los componente de Ionic que utilizaremos 
  los exponemos desde aqui al resto de la app 
  con un app.compononent... 
*/
import {
    IonBadge, IonButton, IonButtons,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle,
    IonCol,
    IonCheckbox,
    IonContent,
    IonFab, IonFabButton, IonFabList,
    IonGrid,
    IonFooter,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll, IonInfiniteScrollContent,
    IonInput,
    IonDatetime,
    IonPicker,
    IonRadio, IonRadioGroup,
    IonItem, IonItemDivider, IonItemSliding, IonItemOption, IonItemOptions,
    IonLabel,
    IonList, IonListHeader,
    IonNav,
    IonNote,
    IonPage,
    IonProgressBar,
    IonReorder, IonReorderGroup,
    IonRow,
    IonSearchbar,
    IonSegment, IonSegmentButton,
    IonSelect, IonSelectOption,
    IonSpinner,
    IonTabs, IonTabBar, IonTabButton,
    IonThumbnail,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar
} from '@ionic/vue'


/* Importamos y configuramos axios */
import axios from 'axios'
axios.defaults.baseURL = URL
axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"


/** Version 8.2.6  */
import firebase from 'firebase/app'
import 'firebase/auth'


firebase.initializeApp({
    apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
    authDomain: "appgescom.firebaseapp.com",
    projectId: "appgescom",
    storageBucket: "appgescom.appspot.com",
    messagingSenderId: "728244704694",
    appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
    measurementId: "G-KX4GNXBZ0Q",
    databaseURL: "https://appgescom.firebaseio.com"
})


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import 'purecss/build/pure.css'

/* Theme variables */
import './theme/variables.css'
import './registerServiceWorker'

import MultiSelect from 'primevue/multiselect'
import DropDown from 'primevue/dropdown'
import 'primevue/resources/themes/saga-blue/theme.css'; // Importa el tema de PrimeVue
import 'primevue/resources/primevue.min.css'; // Importa los estilos de PrimeVue
import 'primeicons/primeicons.css'; // Importa los iconos de PrimeIcons

/* Funciones generales */
window.miconsola = debugar === true ? console.log : function () { void (0) }
window.fake_console = debugar === true ? console.log : function () { void (0) }
window.fake_console('Paso por MAIN ')

/* Inicializamos la app */
let app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(PrimeVue)

/* Creamos los componentes globales para que sean accesibles */
app.component('ion-progress-bar', IonProgressBar)
app.component('ion-badge', IonBadge)
app.component('ion-button', IonButton)
app.component('ion-buttons', IonButtons)
app.component('ion-card', IonCard)
app.component('ion-card-content', IonCardContent)
app.component('ion-card-header', IonCardHeader)
app.component('ion-card-title', IonCardTitle)
app.component('ion-card-subtitle', IonCardSubtitle)
app.component('ion-checkbox', IonCheckbox)
app.component('ion-col', IonCol)
app.component('ion-content', IonContent)
app.component('ion-fab', IonFab)
app.component('ion-fab-list', IonFabList)
app.component('ion-fab-button', IonFabButton)
app.component('ion-grid', IonGrid)
app.component('ion-footer', IonFooter)
app.component('ion-header', IonHeader)
app.component('ion-icon', IonIcon)
app.component('ion-img', IonImg)
app.component('ion-input', IonInput)
app.component('MultiSelect', MultiSelect)
app.component('DropDown',DropDown)

app.component('ion-datetime', IonDatetime)
app.component('ion-picker', IonPicker)

app.component('ion-infinite-scroll', IonInfiniteScroll)
app.component('ion-infinite-scroll-content', IonInfiniteScrollContent)
app.component('ion-item', IonItem)
app.component('ion-item-divider', IonItemDivider)
app.component('ion-item-sliding', IonItemSliding)
app.component('ion-item-options', IonItemOptions)
app.component('ion-item-option', IonItemOption)
app.component('ion-label', IonLabel)
app.component('ion-list', IonList)
app.component('ion-list-header', IonListHeader)
app.component('ion-nav', IonNav)
app.component('ion-note', IonNote)
app.component('ion-page', IonPage)
app.component('ion-radio', IonRadio)
app.component('ion-radio-group', IonRadioGroup)
app.component('ion-reorder-group', IonReorderGroup)
app.component('ion-reorder', IonReorder)
app.component('ion-row', IonRow)
app.component('ion-searchbar', IonSearchbar)
app.component('ion-segment', IonSegment)
app.component('ion-segment-button', IonSegmentButton)
app.component('ion-select', IonSelect)
app.component('ion-select-option', IonSelectOption)
app.component('ion-spinner', IonSpinner)
app.component('ion-tabs', IonTabs)
app.component('ion-tab-bar', IonTabBar)
app.component('ion-tab-button', IonTabButton)
app.component('ion-thumbnail', IonThumbnail)
app.component('ion-text', IonText)
app.component('ion-title', IonTitle)
app.component('ion-toggle', IonToggle)
app.component('ion-toolbar', IonToolbar)

// Creamos las Variables globales
var self = app.config.globalProperties
self.$http = axios
self.$filters = globalFilters
self.$device = undefined
self.$ruta_imagen = 'https://storage.googleapis.com/mobigescom/'
self.$home = arranque
self.$firebase = firebase
self.$logged = false

// Listener para deeplinks
eventBus().emitter.on('new-deeplink', (new_deeplink) => {
    self.$deeplink = new_deeplink
    window.fake_console('deeplink: ', self.$deeplink)
})

const interceptores = async function (self, be) {
    window.fake_console('Monto los interceptores')
    window.fake_console('self.$router.currentRoute', self.$router.currentRoute)
    self.$http.defaults.headers['key'] = be

    self.$http.interceptors.request.use(req => {
        req.params = req.params || {}
        //req.params['dev'] = self.$deviceUuid
        req.params['ml'] = self.$current_user
        return req
    })

    self.$http.interceptors.response.use(
        res => {
            if (!res.data || res.data.status_log === false) {
                self.$logged = false
                window.fake_console('self.$router.currentRoute', self.$router.currentRoute)
                window.fake_console('self.$deeplink', self.$deeplink)
                self.$router.replace('/login')
                return false
            }
            else {
                return res
            }
        },
        error => {
            if (error.response.status === 404) {
                window.fake_console('Estoy en el error del undefined, pasaremos por login')
                self.$logged = false
                self.$router.replace('/login')
                return false
            }
        }
    )
    return 1
}

// Cargamos las variables de entorno
const procesoInicial = async (desde) => {
    console.log(`Inicio Proceso Inicial desde ${desde}`)
    const res = await Device.getId()
    self.$deviceUuid = res.uuid
    const info = await Device.getInfo()
    self.$isIOS = (info.operatingSystem || '').toLocaleLowerCase() === 'ios'
    const be = await loadStorage('user')
    self.$current_user = await loadStorage('user')
    self.$current_user_name = await loadStorage('user_name')

    eventBus().emitter.emit('deeplink-log', {
        logged: self.$logged
    })
    await interceptores(self, be)

}
export { procesoInicial }


window.addEventListener("load", function () {
    // Intento anular boton
    document.addEventListener("backbutton", function () { }, false)
    self.$NoBackNavigator = function () {
        history.pushState(null, document.title, location.href);
        window.addEventListener('popstate', function () { history.pushState(null, document.title, location.href) })
    }
})

router.isReady().then(async () => {
    await procesoInicial('New MAIN');
    window.fake_console('dentro del router ready')
    app.mount('#app');
    window.fake_console('main', 'App montada')
    defineCustomElements(window);
    const permisos = await Camera.checkPermissions()
    if (!permisos) { await Camera.requestPermissions('camera') }
})

eventBus().emitter.on('device', (dev) => { self.$device = dev })

// Variables globales que se utilizan en el comportamiento de la app
eventBus().emitter.on('user-logged', async (roles) => {
    console.log('en la funcion emit.on de user-logged')
    console.log('esto es roles', roles)

    self.$current_user = roles.usr
    self.$current_user_name = roles.uname
    self.$perfil_user = roles.pf
    self.$pdv_principal = roles.pdv_principal
    self.$scope_pdv = roles.pdv

    self.$pdv_list = roles.pdv_list

    console.log('self.$scope_pdv', self.$scope_pdv)
    self.$racrol = roles.racrol
    self.$cargo_intrages = roles.cargo_intrages
    self.$emulated_user = undefined
    self.$emulated_user_name = undefined

    self.$logged = true

    eventBus().emitter.emit('deeplink-log', { logged: self.$logged })

    await procesoInicial('login')
    self.$router.replace({ name: 'home' })
})
