<template>
  <ion-col>
    <ion-card id="tarjeta" @click="routerTo()">
      <ion-card-header>
        <ion-card-title>Vencidos a más de 30 días </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p class="clientes-vencidos">
          <b>{{ nvencidos }}</b> clientes que suman
          <b class="color-red">{{ $filters.Moneda(tvencidos) }}</b>
        </p>
        <ion-grid no-padding no-margin text-right>
          <ion-row>
            <ion-col v-for="venc in vencidos" :key="venc">
              <div class="vencido-col">
                <div>
                  <span>
                    {{ vencidoSplit(venc.n, 1) }}
                    {{ vencidoSplit(venc.n, 0) }}
                  </span>
                </div>

                <div class="vencido-total red-color">
                  {{ $filters.Moneda(venc.e) }}
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-col>
</template>

<script>
import { personCircleOutline } from "ionicons/icons";

export default {
  name: "VencidosCard",
  props: {
    vencidos: {}
  },
  data: () => ({
    personCircleOutline,
    nvencidos: 0,
    tvencidos: 0
  }),
  mounted() {
    if (this.vencidos != null) {
      this.tvencidos = this.vencidos.reduce((m, v) => {
        m += v.e
        return m
      }, 0)

      this.nvencidos = this.vencidos.length
    }
    else {
      this.tvencidos = []
      this.nvencidos = 0
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat().format(num);
    },
    vencidoSplit(text, part) {
      const parts = text.split('-')
      return parts[part]
    },
    routerTo() {
      this.$router.replace({ 'name': 'cartera', params: { origen: 'home' } })
    }
  }
}
</script>

<style scoped>
ion-card {
  /*margin: 0.5rem 4px !important;*/
  margin: -1.5em 0 0.5em 0 !important;
  height: 8rem !important;
  width: 100% !important;
}

ion-card-title {
  text-align: center;
  color: white;
  font-size: 14px;
  background-color: #33a2df;
  padding: 8px 0 6px;
}

#tarjeta ion-card-header {
  padding: 0;
  margin-bottom: 10px;
}

#tarjeta ion-card-content {
  margin: 10px;
}


.red-color {
  color: #fc0f1d !important;
  display: block;
}

.clientes-vencidos {
  color: #203360 !important;
}


.vencido-col ion-icon {
  position: absolute;

}

.color-red {
  color: #fc0f1d !important;
}


.vencido-total.red-color {
  font-weight: bolder;

}

@media (max-width: 900px) {
  
  ion-card-content {
    padding: 0 10px 10px 5px;
  }

  .green-color,
  .red-color {
    margin-bottom: 2px;
  }

  .clientes-vencidos {
    margin-left: 10px;
  }

  .vencido-col {
    border: none;
    min-height: 15px;
    padding: 0px 20px 0px 3px;
  }

  .vencido-col ion-icon {
    top: 16px;
  }

  .vencido-total {
    position: absolute;
    top: 2px;
    right: 0px;
    font-size: 16px !important;
  }

}
</style>
