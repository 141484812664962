<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title class="derecha">
                    Mobiges<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
            </ion-toolbar>
        </ion-header>


        <ion-row class="header">
            <ion-col class="list-title">
                <ion-button color="primary" @click="goBack()">
                    <ion-icon :icon="arrowBackOutline" padding></ion-icon>
                </ion-button>
                <h3>{{ tipo }} pendientes</h3>
            </ion-col>
        </ion-row>


        <ion-content id="main">
            <div class="ion-page" id="main-content">
                <ion-content class="data-content">
                    <ion-grid style="width: 100%;">
                        <div>
                            <DataTable :value="datos_tabla" v-model:expandedRows="expandedRows" dataKey="counter" :loading="loading"
                                tableStyle="min-width: 50rem" stripedRows responsiveLayout="scroll" sortMode="single"
                                sortField="n" :sortOrder="2" v-model:filters="filters1" :globalFilterFields="['n']"
                                ><!-- @row-click="toggleRowExpansion($event)" -->

                                <template #header>
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters1['global'].value" :placeholder="'Filtrar ' + tipo"
                                                class="input-search" />
                                        </span>
                                    </div>
                                </template>

                                <template #empty>
                                    No customers found.
                                </template>

                                <template #loading>
                                    Loading customers data. Please wait.
                                </template>

                                <Column expander headerStyle="width: 3rem"/>
                                <Column field="n" header="Nombre">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data.n }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="vbru" header="Importe" style="text-align: right;">
                                    <template #body="slotProps">
                                        <div>
                                            {{ formatCurrency(calculateTotal(slotProps.data.n)) }} €
                                        </div>
                                    </template>
                                </Column>

                                <template #expansion="slotProps">
                                    <div class="p-3">
                                        <DataTable :value="slotProps.data.detail_data" @row-click="rowClick($event)"
                                        responsiveLayout="scroll">
                                            <Column field="n" :header="getHeader('n')"></Column>
                                            <Column field="np" :header="getHeader('np')"></Column>
                                            <Column field="dt_o" :header="getHeader('dt_o')">
                                                <template #body="slotProps">
                                                    {{ formatDate(slotProps.data.dt_o) }}
                                                </template>
                                            </Column>
                                            <Column field="vbru" :header="getHeader('vbru')" style="text-align: right">
                                                <template #body="slotProps">
                                                    {{ formatCurrency(slotProps.data.vbru) }}
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </ion-grid>
                </ion-content>
            </div>
        </ion-content>
    </ion-page>
</template>


<script>

import { defineComponent } from 'vue'

import DataTable from "primevue/datatable"
import Column from "primevue/column"
import InputText from "primevue/inputtext"
import { FilterMatchMode } from "primevue/api"
import { arrowBackOutline, search } from "ionicons/icons"
import { get_customer_docs } from '@/utilities/services/home_services'

export default defineComponent({
    name: "TableDataPage",
    components: {
        DataTable,
        Column,
        InputText
    },
    props: {
        datos: undefined,
        origen: undefined,
        tipo: undefined,
        cliente: undefined
    },
    data: () => ({
        datos_tabla: [],
        showDetail: false,
        filters1: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            np: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            dt_o: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            vbru: { value: null, matchMode: FilterMatchMode.IN },
            pen: { value: null, matchMode: FilterMatchMode.EQUALS }
        },
        documentoActual: undefined,
        nombres_col: {
            "Pedidos": {
                "n": "Nombre Cliente",
                "np": "Pedido",
                "dt_o": "Fecha",
                "vbru": "Importe"
            },
            "Ofertas": {
                "n": "Nombre Cliente",
                "np": "Presupuesto",
                "dt_o": "Fecha",
                "vbru": "Importe"
            },
            "Documentos": {
                "n": "Nombre Cliente",
                "np": "Documento",
                "dt_o": "F. Vencimiento",
                "vbru": "Importe"
            }
        },
        columnas: ['n', 'np', 'dt_o', 'vbru'],
        expandedRows: [],
        loading: false,
        arrowBackOutline,
        search
    }),
    primevue: {
        theme: "md-light-indigo",
        ripple: true,
        components: ["DataTable", "Column", "InputText"],
    },
    async mounted() {
        this.$NoBackNavigator()

        this.loading = true
        
        console.log('this.datos', this.datos)
        if (this.datos != 'undefined' && this.datos != undefined) {
            this.datos_tabla = JSON.parse(this.datos)
        } else {
            // Vengo del home, consultamos los datos
            console.log('TableDataPage this.datos', this.datos)
            this.datos_tabla = await this.fetchTableData()
            console.log('this.datos_tabla', this.datos_tabla)
        }

        // Agrupamos por n y añadimos en un array de vbrus y lo sumamos todo
        const groupedData = this.datos_tabla.reduce((acc, obj) => {
            const key = obj.n;
            if (!acc[key]) {
                acc[key] = {
                    n: obj.n,
                    vbru: []
                };
            }
            acc[key].vbru.push(obj.vbru);
            return acc;
        }, {});

        var counter = 0;
        // Convertir el objeto en un array de objetos y ajustar la estructura para DataTable
        this.datos_tabla = Object.values(groupedData).map(item => ({
            counter: counter++,
            n: item.n,
            vbru: item.vbru.reduce((acc, value) => acc + value, 0), // Sumar los valores de vbru
            detail_data: this.datos_tabla.filter(data => data.n === item.n) // Todos los datos pero por separado para verlo en el expansion
        }));

        this.showDetail = false
        this.loading = false
    },
    methods: {
        goBack() {
            this.$router.replace({ 'name': this.origen, params: { cliente: this.cliente } })
        },
        async fetchTableData() {
            console.log('soy tipo ', this.tipo)
            var data = []
            if (this.tipo == 'Pedidos') {
                data = await get_customer_docs(this, 'pedidos', undefined)
            } else if (this.tipo == 'Ofertas') {
                data = await get_customer_docs(this, 'ofertas', undefined)
            } else if (this.tipo == 'Documentos') {
                data = await get_customer_docs(this, 'documentos', undefined)
            }
            return data
        },
        calculateTotal(grupo) {
            let total = this.datos_tabla.filter(e => { return e.n == grupo }).reduce((m, v) => { return m = m + parseFloat(v.vbru) }, 0)
            return new Intl.NumberFormat().format(total)
        },
        formatCurrency(value) {
            return value.toLocaleString("es-ES", {
                style: "currency",
                currency: "EUR"
            })
        },
        formatDate(d) {
            d = typeof d == "string" ? d : d.toString()
            return d.slice(6, 8) + "/" + d.slice(4, 6) + "/" + d.slice(0, 4)
        },
        rowClick(e) {
            if (this.tipo != 'Documentos') {
                this.documentoActual = e.data.p + "." + e.data.s + "." + e.data.np
                console.log('this.documentoActual', this.documentoActual)
                this.showDetail = true

                const tipo = this.tipo == 'Pedidos' ? 'pedido' : this.tipo == 'Ofertas' ? 'oferta' : this.tipo
                this.$router.replace({ 'name': tipo, params: { tipo: tipo, codemp: e.data.p, codsuc: e.data.s, numped: e.data.np, titulo: this.tipo, origen: this.tipo.toLowerCase() } })
                //this.$forceUpdate()
            }
        },
        getHeader(field) {
            return this.nombres_col[this.tipo][field];
        }
        /*toggleRowExpansion(event) {
            // Verifica si el ID de la fila ya está en expandedRows
            const isExpanded = this.expandedRows.find((id) => id === event.data.id);

            if (isExpanded) {
                // Si está expandido, lo eliminamos de la lista
                const index = this.expandedRows.indexOf(isExpanded);
                if (index !== -1) {
                this.expandedRows.splice(index, 1);
                }
            } else {
                // Si no está expandido, lo agregamos a la lista
                this.expandedRows.push(event.data.id);
            }

            console.log(this.expandedRows);
        }*/
    }
})

</script>

<style scoped>
ion-title {
    padding-right: 4.5rem !important;
}

.list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-title h3,
.p-input-icon-left,
.p-inputtext {
    width: 100%;
}

.pedidos-cliente ion-grid {
    width: 96%;
}

.details ion-grid {
    width: 100%;
}

.total-euros {
    width: 40%;
    text-align: right;
    white-space: nowrap;
}

.right-menu {
    background: transparent !important;
    --side-max-width: 350px;
    width: 350px;
}

.data-content ion-grid {
    width: 100%;
    padding-top: 0px;
}

.mobile-no-show {
    display: none;
}

.importe {
    white-space: nowrap;
}

/* Agrega estilos CSS para filas expandibles */
.expandable-row {
    cursor: pointer;
}

/* Estilo para la fila expandida */
.p-datatable-expanded-row {
    background-color: #f5f5f5;
}

@media (max-width: 900px) {
    ion-grid {
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
    }

    .header {
        background-color: #33a2df;
        color: #FFFFFF;
    }

    .header h3 {
        color: #ffffff;
        font-size: 16px;
        padding-bottom: 0px;
        margin-top: 8px;
    }

    .input-search {
        width: 100%;
        display: inline-block;
        height: 30px;
        padding-left: 35px;
    }

    .total-euros {
        width: 100%;
        text-align: right;
    }

    .right-menu {
        background: transparent !important;
    }

    ion-button {
        font-size: 10px;
    }

    ion-item {
        font-size: 14px;
    }

    .details-data br {
        display: none;
    }

    .pi::before {
        font-size: 12px;
    }

}
</style>
