    import eventBus from "@/event-bus";
    import {  teclasFuncion } from '@/utilities/global-constants';

    const consola =  function() {void(0)};
    let contador = 0 
    var lectura_generica = ''
    let bindLectorGenerico
    



   /** Listener, tiene que estar con la función para poder escupir datos a la variables compartida  */
   const scanListenerArticulos = function(elId, e ) {
    if (e.keyCode == 13) {
        e.preventDefault();
        e.stopPropagation();
        window.fake_console(lectura_generica)
        eventBus().emitter.emit(`lectura_generica:${elId}`, lectura_generica)
        lectura_generica = ''
    }
    else  {
        /*lectura_generica+= e.keyCode == 32 ? ' ' : e.keyCode!=16 ? e.key : '';*/
        lectura_generica+= e.keyCode == 32 ? ' ' : teclasFuncion.indexOf(e.keyCode)==-1 ? e.key : '';
    }
}


/** FUNCION DE ESCANEADO  */
const scanGenericStart =   async function (elId, comentario) { 
    consola(comentario )
    lectura_generica = '';
    consola(elId);
    bindLectorGenerico = scanListenerArticulos.bind(null, elId)
    consola('31: ', elId, lectura_generica)
    document.addEventListener(`keydown`, bindLectorGenerico);   
    contador+=1
    consola('CONTADOR SUBE : '+contador)
    eventBus().emitter.on(`lectura_generica:${elId}`, async  function (art_from_keyboard) {
        consola(`Articulo escaneada: verificamos lectura lectura_generica:${elId} | ${art_from_keyboard}`)
        lectura_generica  = ''
    })
}

const scanGenericStop = async function( elId ) {
    lectura_generica = '';
    try {
        document.removeEventListener(`keydown`,bindLectorGenerico);
        eventBus().emitter.off(`lectura_generica:${elId}`)
        contador-=1
        consola('Saliendo, contador baja : '+contador)
        return true
    }
    catch (ex) {
        consola('Tengo un error ', ex);
        return false 
    }
}


    
export { scanGenericStart, scanGenericStop   }