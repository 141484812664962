
import { createRouter, createWebHistory } from '@ionic/vue-router'
import eventBus from "@/event-bus"

let isIOS = undefined
let logged = false
let nombres_clientes = false

eventBus().emitter.on('memory-data', (dl) => {
  isIOS = dl.isIOS
  logged = dl.logged
  nombres_clientes = dl.clientes_pdv.reduce((m, v) => {
    const { cliente, nombre } = v
    m[cliente] = nombre
    return m
  }, {})
})


eventBus().emitter.on('deeplink-log', (dl) => {
  window.fake_console('deep-link logging....')
  isIOS = dl.isIOS
  logged = dl.logged
})


import Login from '@/pages/Login.vue'
import HomePage from '@/pages/HomePage.vue'
import TableDataPage from '@/pages/TableDataPage.vue'
//import DocumentsDataPage from '@/pages/DocumentsDataPage.vue'
import DetalleDocumento from '@/components/DetalleDocumentos/DetalleDocumento.vue'
import CustomerList from '@/pages/CustomerList.vue'
import CustomerHomePage from '@/pages/CustomerHomePage.vue'
import CarteraPage from '@/pages/CarteraPage.vue'
import NewRacPage from '@/pages/NewRacPage.vue'
import ListaMostradorPage from '@/pages/carrito/ListaMostradorPage.vue'
import BuscarFavoritosClientePage from '@/pages/carrito/BuscarFavoritosClientePage.vue'
import BuscarProdutoPage from '@/pages/carrito/BuscarProductoPage.vue'
import CrearPedidoClientePage from '@/pages/carrito/CrearPedidoClientePage.vue'

const routes = [
  {
    path: '/',
    name: 'plain_login',
    principal: 'si',
    component: Login
  },
  {
    path: '/login',
    principal: 'si',
    name: 'login',
    props: true,
    component: Login
  },
  {
    path: '/home',
    principal: 'si',
    name: 'home',
    component: HomePage
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    props: true,
    component: TableDataPage
  },
  {
    path: '/ofertas',
    name: 'ofertas',
    props: true,
    component: TableDataPage
  },
  {
    path: '/documentos',
    name: 'documentos',
    props: true,
    component: TableDataPage //DocumentsDataPage
  },
  {
    path: '/pedido',
    name: 'pedido',
    props: true,
    component: DetalleDocumento //DocumentsDataPage
  },
  {
    path: '/oferta',
    name: 'oferta',
    props: true,
    component: DetalleDocumento //DocumentsDataPage
  },
  {
    path: '/lista_clientes',
    name: 'lista_clientes',
    props: true,
    component: CustomerList
  },
  {
    path: '/home_cliente',
    name: 'home_cliente',
    props: true,
    component: CustomerHomePage
  },
  {
    path: '/cartera',
    name: 'cartera',
    props: true,
    component: CarteraPage
  },
  {
    path: '/new-rac',
    name: 'newrac',
    props: true,
    component: NewRacPage
  },
  {
    path: '/carrito',
    name: 'carrito',
    props: true,
    component: ListaMostradorPage
  },
  {
    path: '/favoritos-mostrador/:cliente',
    name: 'favoritos_mostrador',
    component: BuscarFavoritosClientePage   , 
    props: true
  },
  {
    path: '/busqueda-mostrador/:cliente',
    name: 'busqueda_mostrador',
    component: BuscarProdutoPage, 
    props: true
  },
  {
    path: '/crear-pedido',
    name: 'crear-pedido',
    component: CrearPedidoClientePage,
    props: true
  }
]

const principales = routes.reduce((m, v) => {
  if (v.principal == 'si') { m.push(v.name) }
  return m
}, [])


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  void (from)
  let new_deeplink

  console.log('router beforeEach to', to)
  console.log('router beforeEach from', from)

  if (principales.indexOf(to.name) == -1) {
    new_deeplink = to.fullPath
    eventBus().emitter.emit('new-deeplink', new_deeplink)
    window.fake_console('lanzando nuevo! ', new_deeplink)
  }
  window.fake_console('to', to, 'logged', logged)

  to.meta.isIOS = isIOS
  if (to.params.cliente) {
    to.meta.nombre_cliente = nombres_clientes[to.params.cliente]
  }

  window.fake_console('router con parametros', to.params)

  window.fake_console('vamos al login?', logged === false && to.name != 'login')


  /** si no estamos logados pasamos por la carga  */
  if (logged === false && to.name != 'login') {
    next('/login')
  }
  else {
    next()
  }
})

export default router
