<template>
  <ion-content>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-menu-toggle slot="start" color="secondary" style="position:absolute">
          <ion-menu-button color="light"><ion-icon :icon="close"></ion-icon></ion-menu-button>
        </ion-menu-toggle>
        <ion-img class="ion-text-center" style="width: 50%; margin:0 auto;" src="assets/logo-ges.jpg" />
      </ion-toolbar>
      <ion-card-content>
        <div class="current-customer" v-if="customerSel !=null">
          <ion-icon :icon="person" slot="start" class="ico-actual"></ion-icon>
          <h2 padding-left>
            <strong>{{ customerSel.n }}</strong><br>
            <span>{{ customerSel.nf }}<br>
           <b>Cuenta: </b>{{ customerSel.c }}<br>
            <b>Global: </b>{{ customerSel.i }}</span>
          </h2>
        </div>
        <router-link :to="'/customers-list'" class="change-customer">
          <ion-button color="secondary" expand="block" class="ion-text-left"><ion-icon :icon="sync" slot="start" class="ico-change"></ion-icon> Cambiar cliente </ion-button>
        </router-link>
      </ion-card-content>
    </ion-header>
    <ion-list>
      <ion-item>
        <ion-label @click="menuClick('/comercial/')">
            <ion-icon :icon="home" slot="start"  style="padding-right: 10px; padding-top: 4px"></ion-icon>
            Inicio (Comercial)
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label @click="checkHome()">
            <ion-icon :icon="person" slot="start" style="padding-right: 10px; padding-top: 4px"></ion-icon>
            Home (Cliente)
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
            <ion-icon :icon="bulb" slot="start" style="padding-right: 10px; padding-top: 4px"></ion-icon>
            Lo de Siempre...
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
            <ion-icon :icon="repeat" slot="start"></ion-icon>
          <label class="lbl-transacciones">Mis transacciones: </label>
        </ion-label>
      </ion-item>
      <ion-item style="padding-left: 25px" rout>
        <ion-label  @click="menuClick('/home/pedidos-clientes/')">
          <ion-icon :icon="calculator" slot="start" style="padding-right: 10px"></ion-icon>
          Pedidos
        </ion-label>
      </ion-item>
      <ion-item style="padding-left: 25px">
        <ion-label @click="menuClick('/home/ofertas-cliente/')">
            <ion-icon :icon="layers" slot="start" style="padding-right: 10px"></ion-icon>
          Ofertas
        </ion-label>
      </ion-item>
      <ion-item style="padding-left: 25px">
        <ion-label @click="menuClick('/home/albaranes-clientes/')">
            <ion-icon style="padding-right: 10px" :icon="calendarNumber" slot="start"></ion-icon>
          Albaranes
        </ion-label>
      </ion-item>
      <ion-item style="padding-left: 25px">
        <ion-label @click="menuClick('/home/facturas-clientes/')">
            <ion-icon :icon="analytics" slot="start" style="padding-right: 10px"></ion-icon>
            Facturas
        </ion-label>
      </ion-item>
      <ion-item style="padding-left: 25px">
        <ion-label @click="menuClick('/home-cart/')">
            <ion-icon :icon="cart" slot="start" style="padding-right: 10px"></ion-icon>
          "Carritos" del cliente
        </ion-label>
      </ion-item>
      <br><br><br><br>
      <!--<ion-item style="padding-left: 50px">
        <ion-label>
          <router-link :to="'/documentos-clientes/'">
            <ion-icon :icon="cash" slot="start" style="padding-right: 10px">
            </ion-icon>
            Pagares Pendientes
          </router-link>
        </ion-label>
      </ion-item>-->
    </ion-list>
  </ion-content>
</template>

<script>
import {
  close,
  cart,
  cash,
  analytics,
  home,
  person,
  bulb,
  calendarNumber,
  calculator,
  layers,
  repeat,
  barcode,
  sync,
} from "ionicons/icons"

export default {
  component: {},
  name: "RightMenu",
  data: () => ({
    close,
    sync,
    person,
    barcode,
    cart,
    cash,
    analytics,
    home,
    bulb,
    calculator,
    calendarNumber,
    layers,
    repeat,
    customer: {}
  }),
  emits: ["resetHome"],
  props:['customerSel'],
  mounted() {
    this.customer = this.customerSel;
  },
  update() {
    this.customer = this.customerSel;
  },
  methods:{
    checkHome(){
      if(this.$route.name==='home'){
        this.$emit('resetHome', true)
      }else{
        this.$router.replace('/home/')
      }
      document.querySelector('ion-menu-toggle ').click()
    },
    menuClick(url){
      document.querySelector('ion-menu-toggle ').click()
      this.$router.replace(url)
    },
  }
};
</script>
<style>
ion-menu .content-ltr{
  background:#0A3061 !important;
  --background:#0A3061 !important;
}
</style>
<style scoped>
ion-list{
  background-color: #0A3061;
}
ion-item{
  --background: #0A3061;
  color: #FFF !important;
}
ion-header{
  background-color: #0A3061;
  padding: 30px 0 0;
}
ion-toolbar{
  padding-bottom: 30px;
}
.current-customer{
  color: #FFF;
  display: flex;
  flex-direction: row;
  padding: 0 30px 25px;
  justify-content: flex-start;
}
.current-customer ion-icon{
  margin-right: 15px;
  margin-top:5px;
  padding: 3px 6px;
  font-size: 20px;
}
.current-customer h2{
  font-size: 20px;
}
.current-customer h2 span{
  color: #E4F2FE;
  font-size:14px;
}

.change-customer{
  text-decoration: none;
  padding-top: 15px;
  width: 80%;
  display: block;
  margin: 0 auto;
}

.card-full-width {
  width: 100%;
}
.ico-actual {
  color: #203360;
  background: #fff;
  border-radius: 20px;
  padding: 5px;
  font-size: 16px;
}

ion-label a ion-icon {
  padding-right: 10px;
  padding-top: 4px;
}
ion-label a {
  text-decoration: none;
  color: #FFF;
}
.ioncard-title {
  font-size: 14px;
}
.ioncard-header {
  padding: 7px 5px 5px 15px;
}
.ioncard-content {
  padding: 0;
}

.lbl-transacciones{
  padding-left: 10px;
  display: inline-block;;
}

ion-item ion-label{
  cursor:pointer;
}
@media (max-width: 900px) {
  ion-header{
    padding-top: 20px;
  }
  ion-toolbar{
    padding-bottom:20px;
  }
  ion-card-content{
    padding: 0 0 0 20px
  }
  .current-customer{
    padding: 0;
  }
  .current-customer h2{
    font-size: 15px;
  }
  .current-customer h2 span{
    font-size: 13px;
  }
  .change-customer{
    padding-top: 10px;
  }
  ion-buttons{
    font-size:14px;
  }
  ion-item{
    font-size: 14px;
    height:35px;
  }
  .list-title h3{
    padding-bottom:0;
  }
}
</style>
