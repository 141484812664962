/** Constantes globales utilizadas en el conjunto de la aplicación  */

/** Delay de actualización (datos de disco, no actualizamos desde server) */
const DELAY = 15000 // 900000 

/** Variables de control para identificar los distintos tipos de lecturas  */
const esSalida = /^(?<tipmov>CV|PD)[?|_](?<codemp>\d{3})[?|_](?<codsuc>\d{2})[?|_](?<docume>.+)[?|_](?<codigo>\d{1,7})[?|_](?<fecdoc>\d{8})$/
const esAlbaran = /^(?<tipmov>CV)[?|_](?<codemp>\d{3})[?|_](?<codsuc>\d{2})[?|_](?<numalb>\d{1,10})[?|_](?<codcli>\d{1,7})[?|_](?<fecalb>\d{8})$/
/** key  */
//const mapboxkey = 'pk.eyJ1IjoiYXBwcy1nZXMiLCJhIjoiY2xjZjIxNzdoMW9rODN4b3dnaTZ4cWtxOSJ9.3OK_E680PlPZVK0hsGVmzw'

/**test key */
const mapboxkey = 'pk.eyJ1IjoiYXBwcy1nZXMiLCJhIjoiY2xjZjF5a3Y2MXQwZzNxbXBxZ3FtOGlxeSJ9.1vatwxki3UgmIIfttsocng'
const GoogKey = 'AIzaSyANMYv20660KcZXS_EzgWS43ZyGumNgnHA';
const esEntrada = /^(?<tipmov>PE|CD)[?|_](?<codemp>\d{3})[?|_](?<codsuc>\d{2})[?|_](?<docume>.+)[?|_](?<codigo>\d{1,7})_(?<fecdoc>\d{8})$/
const esEnvio = /^ENV:(?<codemp>\d{3}):(?<fecenv>\d{8}):\d{6}:\d{3}$/

const esUbicacion = /^[A-Za-z]{3}\d{2} \d{2} \d{2}( \d{2})*$/
const esEan = /^\d{5,20}$/
const esArticulo = /^[A-Z0-9&]{3}\..{2,50}$/
const marcasSchneider = /^(AES|APC|ARV|ENE|EUN|HIM|ITT|MRG|MRT|OVA|PEL|TEL|UEN)\./i
const teclasFuncion = [0,8,9,16,17,18,19,20,27,33,34,35,36,37,38,39,40,91,92,93]
const tipoImagen = new RegExp('^data:image/(?<formato>(jpeg|png));base64,')
const IPS = ['89.7.106.164']

/** Funcion para transformar lecturas de etiquetas viejas de las marcas de schneider */
const transformSchneiderBrand = function(a) {
    return (esArticulo.test(a) && marcasSchneider.test(a)) ? 'SCH.'+a.slice(4) : a      
}

const tipos_reparto = [
    {"k" : "REPARTO" , "v" : "Reparto"}, 
    {"k" : "AGENCIA_GES" , "v" : "Agencia Ges"}, 
    {"k" : "RECOGIDA" , "v" : "Recogida"}, 
    {"k" : "AGENCIA_CLIENTE" , "v" : "Agencia Cliente"}, 
    {"k" : "ESPERA"  , "v" : "En Espera"}
]

const  css_entrega  = {
    "ESPERA" : "rojo", 
    "REPARTO" : "primario" ,
    "AGENCIA_GES": 'naranja', 
    "RECOGIDA": 'verde', 
    "AGENCIA_CLIENTE": 'rojo'
}

const estados_visuales = {
    "ESPERA" : "fak fa-solid-pause-clock",
    0 : "far fa-sharp fa-boxes-stacked", 
    1 : "far fa-sharp fa-truck-ramp-box ", 
    2 : "far fa-sharp fa-truck-fast", 
    3 : "fak fa-sharp-regular-house-circle-check"
}

const estado_envio = {
    0 : "Preparado", 
    1 : "Cargado en camión", 
    2 : "En reparto", 
    3 : "Entregado"

}
const colores_situacion ={
    0 : "rojo", 
    1 : "primario", 
    2 : "terciario", 
    3 : "exito" 
} 



const ubicaciones_entrega = tipos_reparto.reduce((m,v)=> { 
    m[v['k']] = v['v'];
    return m 
}, {})

export {
    esSalida, esEntrada, esUbicacion,  esEan, esArticulo,  DELAY, teclasFuncion, 
    esAlbaran, tipoImagen, estado_envio, 
    css_entrega , ubicaciones_entrega , tipos_reparto, estados_visuales, colores_situacion, 
    transformSchneiderBrand, 
    mapboxkey ,  GoogKey, 
    esEnvio , IPS
}

