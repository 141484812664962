<template>
    <ion-page :key="qt">
        <ion-header>
            <ion-toolbar color="primary" class="ion-justify-content-start ion-align-items-start">
                <ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>

                <ion-title>
                    <ion-row class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40" class="ion-float-left izquierda"
                            :style="isIOS ? 'padding-left: 4rem !important;' : ''" v-if="cleinte">
                            Busqueda ( Cliente {{ cliente }} )
                        </ion-col>
                        <ion-col size="40" class="ion-float-left izquierda"
                            :style="isIOS ? 'padding-left: 4rem !important;' : ''" v-else>
                            Busqueda
                        </ion-col>
                    </ion-row>
                </ion-title>
            </ion-toolbar>
            <ion-toolbar color="primary" style="padding-top:2px;" class="top">
                <ion-searchbar v-model="search_manually" id="busqueda_filtrada_stocks" ref="search_busqueda_filtrada_stocks"
                    :debounce="debounce_milis" @ionClear="onCancel($event)" autocomplete="off" autocorrect="off"
                    autocapitalize="off" spellcheck="false" show-clear-button="always" placeholder="Filtrar productos"
                    inputmode="search" type="search" @ionChange="filtroInputSearch" slot="start" mode="md" size="36">
                </ion-searchbar>
            </ion-toolbar>

            <ion-toolbar color="primary" class="top segmento">
                <ion-segment v-model="instock" mode="ios" color="primary" class="todas">
                    <ion-segment-button value="1">
                        Disponibles Stock
                    </ion-segment-button>
                    <ion-segment-button value="2">
                        Buscador
                    </ion-segment-button>
                </ion-segment>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ion-fab vertical="top" horizontal="end" slot="fixed">
                <ion-fab-button color="darkdanger" @click="goBack">
                    <ion-icon :icon="listSharp"></ion-icon>
                </ion-fab-button>
            </ion-fab>

            <ion-row class="spin ion-justify-content-center " v-if="!showme">
                <ion-col v-if="!showme" class="spin ion-align-self-center" size="48">
                    <ion-spinner v-if="!showme"></ion-spinner>
                </ion-col>
            </ion-row>

            <ion-row v-if="showme">
                <ion-row class="ion-no-padding"
                    v-if="showme && lista_articulos.length > 0 && (!search_manually || search_manually.length == 0)">
                    <ion-col size="44" text-right>
                        <p color="info">
                            <span style="font-size:larger"><b>{{ datos_tot }} articulos en stock, </b>
                                filtra para seleccionar, se muestran los {{ Math.min(limite, lista_articulos.length) }}
                                primeros
                            </span>
                        </p>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-no-padding"
                    v-else-if="showme && lista_articulos.length > 0 && search_manually && search_manually.length > 0">
                    <ion-col size="44" text-right>
                        <p color="info">
                            Tu busqueda "{{ search_manually }}" ha generado <span style="font-size:larger">
                                <b>{{ datos_tot }} resultados </b></span>
                            <br /> Solo se muestran los {{ Math.min(limite, lista_articulos.length) }} primeros
                        </p>
                    </ion-col>
                </ion-row>

                <!-- BUSQUEDA SIN RESULTADOS-->
                <ion-row class="ion-padding ion-margin" v-else>
                    <ion-title class="ion-padding-top ion-margin-top texto-mas-grande">Busqueda sin resultados </ion-title>
                    <div class="ion-padding-top ion-margin-top ion-text-justify texto-mas-grande">
                        <ion-text>
                            No hemos encontrado ningun resultado para tu busqueda
                            "<strong><i>'{{ search_manually }}'</i></strong>"
                            entre los articulos del pdv. <br /><br />
                            Puedes editar manualmente la busqueda, o escanear para seguir buscando.
                            <br /><br />
                        </ion-text>
                        <ion-text v-if="search_google">
                            Si lo prefieres, puedes utilizar el boton 'Google' adjunto para hacer una busqueda avanzada
                        </ion-text>

                        <ion-fab vertical="center" horizontal="end" slot="fixed" v-if="search_google">
                            <ion-fab-button color="success" @click="googleSearch">
                                <ion-icon :icon="logoGoogle"></ion-icon>
                            </ion-fab-button>
                        </ion-fab>
                    </div>
                </ion-row>

                <ion-list v-if="showme && lista_articulos.length > 0" class="ion-no-padding">
                    <ion-item v-for="(product, index) in lista_articulos" v-bind:key="`item-${index}`"
                        class="ion-no-padding ion-no-margin">
                        <ion-row>
                            <ion-col size-offset="1" size="47" class="ion-no-margin  ion-no-padding">
                                <h3 @click="ElementoStockSeleccionado(product)">
                                    {{ $filters.showSku(product.sku) }}
                                </h3>
                                <h4 @click="ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap>
                                    {{ product.shortDescription }}
                                </h4>
                            </ion-col>

                            <!-- RESULTADOS DESDE EL STOCK -->
                            <ion-col size-offset="1" size="32" class="ion-no-margin  ion-no-padding" v-if="!product.search">
                                <h5 @click="ElementoStockSeleccionado(product)" class="full-h5">
                                    <span class="lote" v-if="product.cables !== true">Suc: {{ product.codsuc }}</span>
                                    <!--<span class="lote forzar_derecha" v-if="product.ubi">Ubic : {{ product.ubi }}</span>-->
                                    <span class="lote rojo" v-else-if="product.cables === true">Para pedir a
                                        proveedor</span>
                                    <!--<span class="lote forzar_derecha rojo" v-else>No Ubicado</span>-->
                                </h5>
                                <h5 @click="ElementoStockSeleccionado(product)" class="nombre_cortado" text-wrap
                                    v-if="product.lote != null">
                                    <b><span class="lote">Lote : {{ product.lote }}</span></b>
                                </h5>
                            </ion-col>
                            <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end" v-if="!product.search">
                                <ion-text @click="ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                    <span class="stock" :class="product.dispo < 0 ? 'rojo' : ''">
                                        <b>{{ $filters.Entero(product.dispo) }}</b>
                                    </span>
                                </ion-text>
                                <ion-text @click="ElementoStockSeleccionado(product)" class="mini-lista" text-wrap>
                                    {{ product.unimed }}{{ product.stoalm > 1 ? 's' : '' }}
                                </ion-text>
                            </ion-col>

                            <ion-col size-offset="1" size="32" class="ion-no-margin  ion-no-padding  "
                                v-if="product.search">
                                <h5 @click="ElementoStockSeleccionado(product)" class="full-h5">
                                    <span class="lote rojo">{{ product['dispo_solr'] || 'Pedir a proveedor' }} </span>
                                </h5>
                            </ion-col>
                            <ion-col size="11" class="ion-no-margin  ion-no-padding ion-text-end" v-if="product.search">
                                <ion-text @click="ElementoStockSeleccionado(product)" class="stock" text-wrap>
                                    <span class="stock rojo"><b>{{ $filters.Entero(product.qt_solr) }}</b></span>
                                </ion-text>
                                <ion-text @click="ElementoStockSeleccionado(product)" class="mini-lista rojo" text-wrap>
                                    <span class="lote rojo">{{ product.unimed }}{{ product.qt_solr > 1 ? 's' : '' }}</span>
                                </ion-text>
                            </ion-col>

                            <ion-col size="5" class="ion-no-margin  ion-no-padding ion-text-end"
                                @click="anyadirProducto(product)">
                                <ion-icon :icon="cartSharp" :color="product.search ? 'darkdanger' : 'primary'"
                                    class="muy-grande carro"> </ion-icon>
                            </ion-col>
                        </ion-row>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-content>
    </ion-page>
</template>
    

<script>
import { defineComponent } from 'vue'
import { closeCircleSharp, homeSharp, listSharp, printSharp, thumbsDownSharp, cartSharp, addCircleSharp, logoGoogle } from 'ionicons/icons'


import { stockMostrador, lotes_pdv, ArticulosLote } from '@/utilities/services/carrito_services'

import { useRoute } from 'vue-router'
import { transformSchneiderBrand, esEan } from '@/utilities/global-constants'
import { openAlertaUnidadesCarrito, openAlertaV2 } from '@/utilities/services/alert-utilities'
import { add2MostradorStorage } from '@/utilities/storage/storage-utilities-main'
import { extend, compact } from 'underscore'

export default defineComponent({
    name: "BuscarProductoMostradorPage",
    data() {
        return {
            element_id: 'busqueda-manual-mostrador',
            isIOS: undefined,
            search_manually: undefined,
            closeCircleSharp, homeSharp, printSharp, thumbsDownSharp, cartSharp, listSharp, logoGoogle, addCircleSharp,
            limite: 50,
            datos_tot: undefined,
            lista_articulos: undefined,
            showme: false,
            ruta: undefined,
            qt: false,
            input_activo: false,
            debounce_milis: 300,
            impresion_activa: false,
            search_google: false,
            instock: this.tipo_busqueda == 'busca' ? 2 : 1
        }
    },
    props: ['cliente', 'origen', 'nomcli', 'termino_entrada', 'tipo_busqueda', 'pdv_actual'],
    created() {
        this.isIOS = this.$route.meta.isIOS
    },
    watch: {
        '$route.query.q'() { this.onCancel() },
        async instock(oldval, newval) {
            console.log('instock oldval newval', oldval, newval)
            if (oldval != newval) {
                if (this.instock == 2) {
                    await this.searchProducts()
                }
                else { await this.filtrar() }
            }
        }
    },
    async mounted() {
        console.log('en el mounted')
        const route = useRoute()
        this.ruta = route.fullPath
        this.qt = route.query.q
        if (this.termino_entrada) {
            this.search_manually = this.termino_entrada
            window.fake_console('hay termino de busqueda, lo igualamos ')
            window.fake_console(this.search_manually, this.termino_entrada)
            await this.filtrar()
        }
        else {
            this.onCancel()
        }
    },
    methods: {
        goBack() {
            if (this.cliente && this.cliente != 'undefined') {
                console.log('volvemos al carrito con cliente')
                this.$router.replace({ 'name': 'carrito', params: { 'cliente': this.cliente, 'nomcli': this.nomcli, 'pdv_act': this.pdv_actual } })
            } else {
                console.log('volvemos al carrito sin cliente')
                this.$router.replace({ 'name': 'carrito', params: { 'pdv_act': this.pdv_actual } })
            }
        },
        async filtroInputSearch() {
            window.fake_console(this.input_activo)
            //if (this.input_activo) {
            this.filtrar('Caja de busqueda via filtroInputSearch')
            //}
        },
        onCancel() {
            this.showme = false
            this.search_manually = undefined
            const d = stockMostrador(false, 1)
            this.datos_tot = d.length || 0
            this.lista_articulos = d.slice(0, this.limite)
            this.showme = true
        },

        async filtrar() {
            window.fake_console('Entro en el filtro/busqueda :', this.input_activo, this.instock)
            if (this.instock != 2) {
                window.fake_console(this.search_manually)
                const d = stockMostrador(this.search_manually || false, this.instock)
                window.fake_console(d)
                this.datos_tot = d.length
                this.lista_articulos = d.slice(0, this.limite)
                if (this.datos_tot == 0 && this.instock == 1) {
                    window.fake_console('cambio perspectiva de busqueda ')
                    this.instock = 2
                    await this.searchProducts()
                }
                else {
                    this.showme = true
                }
            }
            else {
                await this.searchProducts()
            }
        },
        async ElementoStockSeleccionado(item) {
            window.fake_console(item)
            this.$router.replace({ 'name': 'detalle_producto', params: { sku: item.sku, origen: `/busqueda-mostrador/${this.cliente}` } })
            window.fake_console(item)
        },
        async anyadirProducto(sku_data) {
            /** abrimos el selector de unidades solo si no hay gestión de lotes o es un 'cable' para hacer pedidos abiertos */
            if (!sku_data.lote || sku_data.cables === true) {

                if (this.cliente != 'undefined') {
                    await openAlertaUnidadesCarrito(this.cliente, this.pdv_actual, sku_data)
                } else {
                    await openAlertaUnidadesCarrito(parseInt('9'+this.pdv_actual), this.pdv_actual, sku_data)
                }
            }
            /** Si es un lote definido, arrastramos todo lo que tenga el lote si poder cambiar cantidad  */
            else {
                const esrollo = (/rollo/ig).test(sku_data.lote)
                const lots = esrollo ? [sku_data] : ArticulosLote(sku_data.lote)
                lots.reduce(async (memo, v) => {
                    const m = await memo
                    const articulo_data = v
                    articulo_data.uds_alb = articulo_data.stoalm
                    articulo_data.tipo = 'stock'

                    console.log('this.cliente', this.cliente)

                    if (this.cliente) {
                        console.log('añadimos articulo cliente', this.cliente, this.pdv_actual, articulo_data)
                        await add2MostradorStorage(this.cliente, this.pdv_actual, articulo_data)
                    } else {
                        console.log('añadimos articulo pdv', parseInt('9'+this.pdv_actual), this.pdv_actual, articulo_data)
                        await add2MostradorStorage(parseInt('9'+this.pdv_actual), this.pdv_actual, articulo_data)
                    }

                    return m
                }, 0)

                openAlertaV2('Productos añadidos', `
                            Hemos añadido ${lots.length} articulo/s del lote ${sku_data.lote} a la lista.  `
                )
            }
            window.fake_console('depues de la apertura de la alerta ')
            if (this.termino_entrada) {
                this.goBack()
            }
        },
        async googleSearch() {
            this.showme = false
            this.search_google = false
            const goog = await this.$http.post(`/google-ean/${this.search_manually}`)
            const resultados_google = goog.data
            if (resultados_google.replace(/ +/g, '') != '') {
                this.searchProducts(resultados_google, 10)
            }
            else {
                this.mensa
                this.showme = true
            }
        },
        async searchProducts(termino, limite_local) {
            this.search_google = esEan.test(termino || this.search_manually)
            this.showme = false

            console.log('this.$scope_pdv', this.$scope_pdv)
            try {
                const busqueda = transformSchneiderBrand(termino || this.search_manually)
                /** Solo un documento (por agilidad) o no -para cuando miramos solo facetas y categorias-  */
                /** Limitamos a 5 con caracter general */
                let url = `/buscar/${this.pdv_actual}?q=${busqueda}&rw=${limite_local || this.limite}&fl=ManufacturerName,score,shortDescription,sku,thumb,unimed,uniser,dis_${this.pdv_actual},ds_${this.pdv_actual}`
                window.fake_console(url)
                const b = await this.$http.get(url)
                const resultado = b.data.response.docs
                this.datos_tot = b.data.response.numFound || 0
                this.lista_articulos = []
                window.fake_console(resultado)
                if (resultado && resultado.length > 0) {
                    const arts = resultado.reduce((ma, va) => {
                        const sk = va['sku']
                        va['dispo_solr'] = va[`dis_${this.pdv_actual}`]
                        va['qt_solr'] = va[`ds_${this.pdv_actual}`] || 0
                        va['dispo'] = Math.ceil(va['qt_solr'] * 0.75)
                        va['stoalm'] = va['qt_solr']
                        va['hay_stock'] = (/inmediat/g).test(va['dispo_solr'])
                        console.log('va', va)
                        console.log('buscarproductopage hay_stock', va['dispo_solr'])
                        let lotes = [{ 'search': 1 }]
                        if (lotes_pdv[sk]) {
                            lotes = compact(lotes_pdv[sk].map(lv => { return lv.dispo && lv.dispo > 0 ? extend({}, lv, { 'search': undefined }) : undefined }))
                        }
                        const new_data = lotes.map(lm => extend({}, va, lm))
                        ma.push(...new_data)
                        return ma
                    }, [])
                    const cables_wip = arts.reduce((m, v) => {
                        if (v.lote && v.sku.slice(0, 3) != 'BOB') {
                            const sk = v.sku
                            const inicializa = { 'cables': true, 'stoalm': 0, 'ubi': undefined, lote: undefined, hay_stock: false, dispo: 0 }
                            m[sk] = Object.assign({}, v, inicializa)
                        }
                        return m
                    }, {})
                    this.lista_articulos = arts.concat(Object.values(cables_wip))
                }
                this.showme = true
            }
            catch (ex) {
                console.log(ex)
                alert('La busqueda no ha generado resultados ')
                this.showme = true
            }
        }

    }
})
</script>

<style scoped lang="scss">
@import "@/theme/footer-styles.scss";
@import "@/theme/seleccion-producto-stock.scss";

ion-segment-button {
    font-size: 1.1rem !important;
}

ion-item>ion-row {
    padding-right: 0rem !important;
}

.in-segment-color {
    color: var(--ion-color-blanco) !important;
}

.segment-button-checked {
    color: var(--ion-color-primary) !important;
    font-weight: bold !important;
}

ion-icon.carro {
    position: relative !important;
    top: 1px !important;
    right: 1px !important;
    font-size: 2rem !important;
}

span.uds_solr {
    padding-right: 1rem !important;
}
</style>