<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <!--<ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                -->
                <ion-menu content-id="main" class="right-menu" :disabled="menuDisabled">
                    <LeftMenu />
                </ion-menu>

                <ion-title class="derecha">
                    Mobiges<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content v-if="!showme">
            <ion-row class="spin ion-justify-content-center " v-if="!showme">
                <ion-col v-if="!showme" class="spin ion-align-self-center" size="48">
                    <ion-spinner v-if="!showme"></ion-spinner>
                </ion-col>
            </ion-row>
        </ion-content>

        <ion-content v-else>
            <p style="padding: 10px">
                Hola <b>{{ user_name }}</b><br />
                Estos son los datos principales para tu gestión a fecha actual.

                <ion-item v-if="emulado">
                    <p class="centro">Estás conectado como <b>{{ emulado }}</b></p>
                    <ion-button class="derecha" @click="deshacerEmulacion()">Desconectar</ion-button>
                </ion-item>

                <ion-col v-else-if="user_data.red_comercial" size="12" class="ion-text-right">
                    <ion-item>
                        <ion-select v-model="emulado" placeholder="Emular usuario" okText="Emular usuario"
                            cancelText="Cancelar" @ionChange="changeUser()">
                            <ion-select-option v-for="item in user_data.red_comercial" :key="item['d']" :value="item['d']">
                                {{ item['d'] }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>

                <ion-col size="12" class="ion-text-right">
                    <ion-item>
                        <ion-button @click="FiltrarCliente()" style="width:100%">
                            <p>Selecciona cliente</p>
                        </ion-button>
                    </ion-item>
                </ion-col>
            </p>

            <ion-grid>
                <ion-row>
                    <ion-col>
                        <SituacionVentasCard :ventas="user_data.ventas.home.venta" />
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <VencidosCard :vencidos="user_data.ventas.home.vencido" />
                    </ion-col>
                    <ion-col>
                        <TarjetaHome link="documentos" :titulo="getTituloTarjeta('Documentos')"
                            :detalle="getImportes('Documentos')" />
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <TarjetaHome link="pedidos" :titulo="getTituloTarjeta('Pedidos')"
                            :detalle="getImportes('Pedidos')" />
                    </ion-col>
                    <ion-col>
                        <TarjetaHome link="ofertas" :titulo="getTituloTarjeta('Ofertas')"
                            :detalle="getImportes('Ofertas')" />
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>

        <ion-tab-bar slot="bottom" color="primary">
            <ion-tab-button @click="changeTab('carrito')" selected>
                <!--<i class="fak fa-regular-truck-circle-question tiempo"></i>-->
                <ion-label>Consulta<br />Stock</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="proyecto" disabled>
                <!--<i class="fas fa-sharp fa-truck-ramp-box tiempo" />-->
                <ion-label>Crear<br />Proyecto</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="visita" disabled>
                <!--<i class="fas fa-route tiempo" />-->
                <ion-label>Reportar<br />Visita</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="construdata" disabled>
                <!--<i class="fak fa-sharp-regular-house-circle-check tiempo" />-->
                <ion-label>Oport.<br />Construdata</ion-label>
            </ion-tab-button>

        </ion-tab-bar>
    </ion-page>
</template>

<script>

import { defineComponent } from 'vue'
import { homeSharp } from 'ionicons/icons'
import { loadStorage } from '@/utilities/storage/storage-utilities-main'
import { get_ce_data, emularUsuario, deshacerEmulacion } from '@/utilities/services/home_services'

import LeftMenu from '@/components/LeftMenu'
import TarjetaHome from '@/components/HomeComercial/TarjetaHome'
//import CustomerList from '@/components/HomeComercial/CustomerList'

import SituacionVentasCard from '@/components/HomeComercial/SituacionVentasCard'
import VencidosCard from '@/components/HomeComercial/VencidosCard'


export default defineComponent({
    name: "HomePage",
    components: { LeftMenu, TarjetaHome, SituacionVentasCard, VencidosCard/*, CustomerListMenu*/ },
    props: ['customers'],
    data: () => ({
        homeSharp,
        menuDisabled: false,
        showme: false,
        user: undefined,
        user_name: undefined,
        user_data: undefined,
        clienteActual: undefined
    }),
    async created() {
        this.showme = false
        await this.actualiza_vars()
        this.showme = true
    },
    async mounted() {
        this.$NoBackNavigator()
    },
    methods: {
        async get_ce_data() {
            const self = this
            this.user_data = await get_ce_data(self)
            console.log('this.user_data', this.user_data)
        },
        async emularUsuario() {
            this.showme = false
            const self = this
            await emularUsuario(self)
            await this.actualiza_vars()
            this.showme = true
        },
        async deshacerEmulacion() {
            this.showme = false
            const self = this
            await deshacerEmulacion(self)
            this.emulado = undefined
            await this.actualiza_vars()
            this.showme = true
        },
        async actualiza_vars() {
            await this.get_ce_data()
            this.user = await loadStorage('user')
            this.user_name = await loadStorage('user_name')
            this.emulado = await loadStorage('emulated_user')
        },
        async changeUser() {
            await this.emularUsuario()
        },
        getTituloTarjeta(tipo) {
            let titulo = ''

            if (tipo == 'Pedidos') {
                titulo = `Pedidos (${this.user_data.pedidos.tot})`
            } else if (tipo == 'Ofertas') {
                titulo = `Ofertas (${this.user_data.ofertas.tot})`
            } else if (tipo == 'Documentos') {
                titulo = `Documentos (${this.user_data.documentos.totdoc})`  
            } else {
                titulo = tipo
            }
            return titulo
        },
        getImportes(tipo) {
            let importe

            if (tipo == 'Pedidos') {
                importe = this.formatCurrency(Math.round(this.user_data.pedidos.pend))
            } else if (tipo == 'Ofertas') {
                importe = this.formatCurrency(Math.round(this.user_data.ofertas.pend))
            } else if (tipo == 'Documentos') {
                importe = this.formatCurrency(Math.round(this.user_data.documentos.toteur))
            } else {
                importe = "- €"
            }
            
            return importe
        },
        formatCurrency(value) {
            return value.toLocaleString("es-ES", {
                style: "currency",
                currency: "EUR"
            })
        },
        async FiltrarCliente() {
            this.$router.replace({ 'name': 'lista_clientes', params: { origen: 'home' } })
        },
        changeTab(ruta) {
            this.$router.replace({ 'name': ruta, params: { origen: 'home' } })
        }
    }
})
</script>

<style scoped>
ion-title {
    padding-right: 4.5rem !important;
}

ion-card {
    font-family: var(--ion-font-family, inherit);
    margin: -1.5em 0 0.5em 0 !important;
    font-size: x-large !important;
}

ion-card-title {
    text-align: center;
}

ion-card-header {
    padding-top: 10px;
    padding-bottom: 10px;
}

ion-note {
    color: whitesmoke;
}


i.fas.en-home,
i.fak.en-home,
i.far.en-home {
    z-index: 9999 !important;
    position: absolute !important;
    top: 0.7rem !important;
    right: 0.7rem !important;
    display: flex !important;
    color: dark !important;
    font-weight: 550 !important;
    font-size: 2rem !important;
    float: right !important;

}
</style>