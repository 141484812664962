//import { saveStockStorage, loadStockStorage } from '@/utilities/storage/storage-utilities-main'
import { transformSchneiderBrand } from '@/utilities/global-constants'
import _ from 'underscore'
var async = require('async')

let eans_pdv, catalogo_pdv, lotes_pdv, stock_pdv, pdv, eans_articulo, stock_total_con_cables

let direcciones_envio, obras_cliente, riesgo_cliente

const get_pdv_data = async (self) => {
    try {
        if (self.origen == 'home' || pdv != self.pdv_actual) {
            pdv = self.pdv_actual

            async.parallel({
                eans_pdv: async () => {
                    let eans = await self.$http.get(`/eans_pdv/${pdv}`)
                    return (null, eans)
                },
                catalogo_pdv: async () => {
                    let catalogo = await self.$http.get(`/catalogo_pdv/${pdv}`)
                    return (null, catalogo)
                },
                lotes_pdv: async () => {
                    let lotes = await self.$http.get(`/lotes_pdv/${pdv}`)
                    return (null, lotes)
                }
            }, (err, results) => {
                eans_pdv = results.eans_pdv.data
                catalogo_pdv = results.catalogo_pdv.data
                lotes_pdv = results.lotes_pdv.data

                eans_articulo = reverseEans(eans_pdv)

                construirStock()
            })
        }

    }
    catch (error) {
        window.fake_console(error)
        console.log(error)
        console.log('L42 (Error) : ', JSON.stringify(error))
    }
}

const reverseEans = function (eans_pdv) {
    return _.reduce(eans_pdv, (m, v) => {
        const ean = String(v.ean).padStart(15, '0')
        const resultado = v.reduce((mm, vv) => {
            mm[vv] = (mm[vv] || '') + `${ean} `;
            return mm
        }, m)
        return resultado
    }, {})
}

const ArticulosLote = function (lote) {
    return stock_pdv.filter(x => (x.lote || '').toLowerCase() == lote.toLowerCase()) || []
}

const stockMostrador = function (termino_original) {
    /** Abrimos la función para que devuelva todo el stock, este o no disponible  */
    const terminos = transformSchneiderBrand(termino_original)

    if (terminos === false) {
        return stock_pdv || []
    }
    else {
        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/, '').replace(/ +/g, '.*')})`, 'i')
        const secondarySearch = terminos.toLowerCase().replace(/ +$/, '').split(' ').map(v => new RegExp(`${v}`, 'i'))
        const [primario, resto] = _.partition(stock_total_con_cables, v => (primarySearch.test(v['tags'])))

        //en segundo lugar desordenados 
        const secundario = resto.filter(v => _.every(secondarySearch, t => t.test(v['tags'])))
        return primario.concat(secundario) || []
    }
}

const construirStock = function () {
    stock_pdv = _.reduce(lotes_pdv, (m, v, k) => {
        const producto = catalogo_pdv[k]

        if (producto != undefined) {
            const tags_template = ['sku', 'ManufacturerName', 'shortDescription', 'refpro'].reduce((mm, vv) => {
                return mm += `${(producto[vv] || '').toLowerCase()} `
            }, '')

            let tags = tags_template + `${(v['lote'] || '').toLowerCase()} `
            if (eans_articulo[k]) {
                tags += `${eans_articulo[k]} `
            }
            m.push(...[Object.assign(v[0], catalogo_pdv[k], { tags })])
        }
        return m
    }, [])

    const cables_wip = stock_pdv.reduce((m, v) => {
        if (v.lote && v.sku.slice(0, 3) != 'BOB') {
            const inicializa = { 'cables': true, 'stoalm': 0, 'ubi': undefined, lote: undefined, hay_stock: false, dispo: 0 }
            m[v.sku] = Object.assign({}, v, inicializa)
        }
        return m
    }, {})

    stock_total_con_cables = stock_pdv.concat(Object.values(cables_wip))
}


const recuperaPrecios = async function (self) {
    var arts = self.datos.map(d => { return d.sku })
    arts = arts.join('||')

    let payload = { arts: arts }
    let res = await self.$http.post(`/precios/${self.cliente}`, payload)
    const precios = res.data

    const lista_articulos = self.datos.reduce((m, v) => {
        if (precios[v['sku']]) {
            v['precio'] = parseFloat(precios[v['sku']]['pr']).toFixed(2)
            v['implin'] = parseFloat(precios[v['sku']]['pr']).toFixed(2) * v['uds_alb']
            v['unipre'] = precios[v['sku']]['up']
        }
        m.push(v)
        return m
    }, [])

    return lista_articulos
}


const recuperaDatosCliente = async function (self) {
    console.log('estamos para vender')

    let [direcciones_envio, obras_cliente, riesgo_cliente] = await Promise.all(
        [
            self.$http.post(`/direcciones_envio/${self.cliente}`),
            self.$http.post(`/obras_cliente/${self.cliente}`),
            self.$http.post(`/riesgo/${self.cliente}`)
        ])

    direcciones_envio = direcciones_envio.data
    obras_cliente = obras_cliente.data
    riesgo_cliente = riesgo_cliente.data

    return { direcciones_envio, obras_cliente, riesgo_cliente }
}


const crearPedidoG2k = async function (self) {
    console.log('cabecera', self.cabecera)
    console.log('detalle', self.detalle)
    let res = await self.$http.post(`crear_pedido_cliente`, { pedido: { cabecera: self.cabecera, detalle: self.detalle } })
    console.log(res)
    return [res.data.status, res.data.comment]
}


export {
    get_pdv_data,
    ArticulosLote,
    construirStock,
    stockMostrador,
    recuperaPrecios,
    recuperaDatosCliente,
    crearPedidoG2k,
    eans_articulo,
    lotes_pdv,
    stock_total_con_cables,
    direcciones_envio,
    obras_cliente,
    riesgo_cliente
}
