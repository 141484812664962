import { Storage } from '@capacitor/storage'
const schema = 'mobiges.ges.digital'

/** ------------------------------------------------------------------------------------------ */
const saveStorage = async function (clave, valor) {
    await Storage.set({ key: `${schema}:${clave}`, value: valor })
}


/** ------------------------------------------------------------------------------------------ */
const removeStorage = async function (clave) {
    await Storage.remove({ key: `${schema}:${clave}` })
}


/** ------------------------------------------------------------------------------------------ */
const saveMainStorage = async function (tk, uid, ml) {
    await Storage.set({ key: `${schema}:authorization`, value: tk })
    await Storage.set({ key: `${schema}:user`, value: uid })
    await Storage.set({ key: `${schema}:user_name`, value: ml })
    await Storage.set({ key: `${schema}:timestamp`, value: Date.now() })
    window.fake_console(`${schema}:user`, uid)
}

/**  ------------------------------------------------------------------------------------------ */
const saveHomeStorage = async function (blq, cli, hom, ofe, pdv, ped) {
    await Storage.set({ key: `${schema}:bloqs`, value: blq })
    await Storage.set({ key: `${schema}:clientes`, value: cli })
    await Storage.set({ key: `${schema}:home`, value: hom })
    await Storage.set({ key: `${schema}:ofertas`, value: ofe })
    await Storage.set({ key: `${schema}:pdvs`, value: pdv })
    await Storage.set({ key: `${schema}:pedidos`, value: ped })
}

/**  ------------------------------------------------------------------------------------------ */
const saveStockStorage = async function (eans, catalogo, lotes_pdv, stock_pdv, pdv, eans_articulo, stock_total_con_cables) {
    console.log('guardo los eans')
    await Storage.set({ key: `${schema}:eans`, value: JSON.stringify(eans) })
    console.log('guardo catalogo')
    await Storage.set({ key: `${schema}:catalogo`, value: JSON.stringify(catalogo) })
    console.log('guardo lotes')
    await Storage.set({ key: `${schema}:lotes`, value: lotes_pdv })
    console.log('guardo stock')
    await Storage.set({ key: `${schema}:stock`, value: stock_pdv })
    console.log('guardo pdv')
    await Storage.set({ key: `${schema}:pdv`, value: pdv })
    console.log('guardo eans_articulo')
    await Storage.set({ key: `${schema}:eans_articulo`, value: eans_articulo })
    console.log('guardo stock_total_cables')
    await Storage.set({ key: `${schema}:stock_total_cables`, value: stock_total_con_cables })
}

const loadStockStorage = async function () {
    //eans, catalogo, lotes_pdv, stock_pdv, pdv, eans_articulo, stock_total_con_cables
    const { value } = await Storage.get({ key: `${schema}:eans` })
    console.log('value', JSON.parse(value))
    return value
}

/** ------------------------------------------------------------------------------------------ */
const loadStorage = async function (clave) {
    try {
        const { value } = await Storage.get({ key: `${schema}:${clave}` })
        return (clave === 'sdfaf' || clave == 'pdv_geoloc') ? JSON.parse(value) : value
    }
    catch (ey) {
        return undefined
    }
}

/** ------------------------------------------------------------------------------------------ */
const LimpiaTodo = async function () {
    window.fake_console('entro en limpia todo.....')
    try {
        const { keys } = await Storage.keys()
        window.fake_console('antes :', keys)
        await Storage.clear()
        window.fake_console('borradas....')
        return true
    }
    catch {
        return false
    }
}

/**  ------------------------------------------------------------------------------------------ */
const saveMostradorStorage = async function (clave, pdv, valor) {
    await Storage.set({ key: `${schema}:mostrador:${pdv}:${clave}`, value: JSON.stringify(valor) })
}

/**  ------------------------------------------------------------------------------------------ */
const add2MostradorStorage = async function (clave, pdv, valor) {
    const { sku, codsuc, lote, tipo } = valor
    const clau = `${sku}.${pdv}.${codsuc}.${!lote ? 'N' : lote}.${!tipo ? 'pedido' : tipo}`
    valor['fila'] = clau
    const previos = await loadMostradorStorage(clave, pdv) || []
    let nuevo = (Array.isArray(previos) ? previos : []).filter(y => y.fila != clau)
    if (valor.uds_alb > 0) { nuevo.unshift(valor) }
    console.log('add2MostradorStorage', `mostrador:${pdv}:${clave}`)
    await Storage.set({ key: `mostrador:${pdv}:${clave}`, value: JSON.stringify(nuevo) })
}

/**  ------------------------------------------------------------------------------------------ */
const loadMostradorStorage = async function (clave, pdv) {
    console.log('loadMostradorStorage', clave, pdv)
    try {
        const k = `mostrador:${pdv}:${clave}`
        const { value } = await Storage.get({ key: k })
        return value == undefined ? undefined : JSON.parse(value)
    }
    catch (ey) {
        return undefined
    }
}

/**  ------------------------------------------------------------------------------------------ */
const delMostradorStorage = async function (clave, pdv) {
    console.log('delMostradorStorage', `mostrador:${pdv}:${clave}`)
    await Storage.remove({ key: `mostrador:${pdv}:${clave}` })
}

export {
    loadStorage, saveStorage, saveMainStorage, saveStockStorage,
    LimpiaTodo, saveHomeStorage, removeStorage, loadStockStorage,
    saveMostradorStorage,
    add2MostradorStorage, loadMostradorStorage, delMostradorStorage
}

