<template>
     <ion-content class="ion-padding" v-if="showme">
          <h3 class="ion-padding-left"  >Busqueda sin resultados </h3>
          <div class="ion-padding-left" v-if="textos.length>0">
              <p >
              Tu busqueda <strong><i>'{{busqueda}}'</i></strong> no ha producido resultados.<br/>
              A continuación te ofrecemos sugerencias alternativas de busqueda:
              </p>
          </div>
        <div class="ion-padding-left" v-if="textos.length==0">
            <p >
            Tu busqueda <strong><i>'{{busqueda}}'</i></strong> no ha producido resultados.<br/>
            No hemos identificado busquedas alternativas para al busqueda.
            Te vamos a redirigir de nuevo a la pagina principal. 
            Por favor, intentalo con otros terminos.
            </p>
        </div>
          <ion-grid class="ion-padding-right">
          <ul  v-if="textos.length>0">
            <ion-list inset="true">
              <ion-item  v-for="(tx,index) in textos" @click="buscalo(tx)" v-bind:key="index">
              {{tx}}
              </ion-item>
            </ion-list>
          </ul>
          <ion-row>
            <ion-col size="24"></ion-col>
            <ion-col size="24">
                <ion-button expand="block" size="large" class="ion-padding" @click="cerrar()">
                    Cerrar
                </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid> 
      </ion-content>
</template>  
<script>
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus";
    import {compact, pluck } from 'underscore';
    export default defineComponent({
      name : "NoSearchResultsSpellcheckComponent",
      
      props : ['busqueda'], 
      data() {
            return {
                textos : undefined,
                showme : false
            }
            },
      async mounted() {
        await this.buscaAlternativas()
        this.showme = true
      },
      methods :  {
        buscalo(tx) {
          eventBus().emitter.emit('buscar-alternativa',tx)
        }, 
        cerrar() {
           eventBus().emitter.emit('cerrar-sin-resultados')
        
          }, 
        async buscaAlternativas(){
          const url  = `/busqueda_alternativa?q=${this.busqueda}`
          const altern = await this.$http.get(url)
          //alert(JSON.stringify(altern))
          const alternativas = altern.data 
          //alert(JSON.stringify(alternativas))
          try {
            this.textos =  compact( pluck(alternativas['spellcheck']['collations'],'collationQuery'))
          }
          catch(ex)
          {
            window.fake_console('no hay ninguna sugerencia')
          }
        }
      }
      })
</script>
