<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary" class="ion-justify-content-start ion-align-items-start">
        <ion-buttons slot="start">
          <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
            <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>
          <ion-row class="ion-justify-content-start ion-align-items-start">
            <ion-col size="40" class="ion-float-left izquierda">
              Ficha del cliente
            </ion-col>
          </ion-row>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="customer-resume">

      <ion-grid v-if="showme">
        <ion-row>
          <ion-col>
            <ion-card class="customer-data" id="findata" v-if="home">
              <ion-card-header>
                <ion-icon :icon="personCircle"></ion-icon>
                <div class="customer-name">{{ home.n }}</div>
                <ion-toggle @click="showAction()" color="secondary" style="float: right; margin-top: -7px"></ion-toggle>
              </ion-card-header>
              <ion-card-content>
                <ion-grid class="customer-data">
                  <ion-row padding-top>
                    <ion-col col-12><span>Nif: </span><br>
                      {{ home.nf }}
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col col-12><span>Forma de pago: </span><br>
                      {{ home.fp }}
                      {{ home.v != undefined && home.v != 0 ? home.v + " dias" : "" }}
                      {{ home.dp != undefined ? "(dia :" + home.dp + ")" : "" }}
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="showMargin">
                    <ion-col col-12><span>Riesgo: </span><br>
                      {{
                        riesgo && riesgo.rtot != undefined
                        ? riesgo.rtot
                        : 0
                      }}
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="bloqs && bloqs.blq">
                    <ion-col col-12 padding-left><span>Bloqueo: </span>
                      <span style="color: red !important">{{ bloqs.blq }}</span>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="circulante" col-12><span>Circulante: </span><br>
                      {{
                        (riesgo && riesgo.cirtot != undefined ? riesgo.cirtot : 0) +
                        (riesgo && riesgo.alb != undefined ? riesgo.alb : 0)
                      }}
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="totven > 0">
                    <ion-col col-4 padding-left><span>Vencidos: </span>
                      {{ totven }} €
                    </ion-col>
                    <!-- <ion-col col-8>{{((home.viv!=undefined ? home.viv : 0)-(home.nov!=undefined ? home.nov : 0))|number:'1.0-0'}}€ </ion-col>-->
                  </ion-row>
                  <ion-row v-if="docnum > 0">
                    <ion-col col-4 padding-left>Documentos
                      <a @click="ir_a($event)">
                        <span><strong>{{ docnum }}</strong></span>
                        {{ tipdoc }}
                        <span><strong>( {{ doctot }} € )</strong>
                          <ion-icon no-margin name="link" class="linker"></ion-icon>
                        </span>
                      </a>
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="bloqs && bloqs.blq">
                    <ion-col col-4 padding-left>Rappel {{ home.rappel == 1 ? "Si" : "No" }}</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card id="pedidos">
              <ion-card-header text-wrap>
                <ion-icon class="head-icon" :icon="receiptOutline"></ion-icon>
                <div class="customer-name">
                  Pedidos<small> ( {{ pedidos }} )</small>
                </div>
                <ion-button @click="verPedidos()" style="float: right; margin-top: -10px" v-if="pedtot != 0">
                  <ion-icon class="button-situacion" :icon="receiptOutline"></ion-icon>
                </ion-button>
              </ion-card-header>
              <ion-card-content class="height100">
                <ion-grid v-if="pedtot != 0" class="grid-situacion">
                  <ion-row class="row-situacion title">
                    <ion-col>Situacion</ion-col>
                    <ion-col class="ion-text-right">Valor</ion-col>
                  </ion-row>
                  <ion-row class="row-situacion">
                    <ion-col class="label-situacion">Entregado</ion-col>
                    <ion-col class="ion-text-right">
                      {{ pedent }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="row-situacion">
                    <ion-col class="label-situacion">Pendiente</ion-col>
                    <ion-col class="ion-text-right">
                      {{ pedpen }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="row-situacion total">
                    <ion-col class="label-situacion">Total</ion-col>
                    <ion-col class="ion-text-right">
                      {{ pedtot }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ion-grid v-if="pedtot == 0" class="row-situacion">
                  <ion-row>
                    <ion-col>No hay pedidos pendientes para este cliente</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card id="ofertas" no-padding>
              <ion-card-header>
                <ion-icon class="head-icon" :icon="pricetagOutline"></ion-icon>
                <div class="customer-name">
                  Ofertas<small> ( {{ ofertas }} )</small>
                </div>
                <ion-button style="float: right; margin-top: -7px" @click="verOfertas()" v-if="ofetot != 0">
                  <ion-icon class="button-situacion" :icon="pricetagOutline"></ion-icon>
                </ion-button>
              </ion-card-header>
              <ion-card-content>
                <ion-grid class="grid-situacion" v-if="ofetot != 0">
                  <ion-row style="font-weight: bold" class="row-situacion title">
                    <ion-col col-5>Mes</ion-col>
                    <ion-col class="ion-text-right">Valor</ion-col>
                  </ion-row>
                  <ion-row v-for="m in meses" :key="m" class="row-situacion meses">
                    <ion-col class="label-months">{{ m }}</ion-col>
                    <ion-col class="ion-text-right">{{ ofertas_sum[m].v }} <b>{{ Math.trunc(ofertas_sum[m].n) }}</b></ion-col>
                  </ion-row>
                </ion-grid>
                <ion-grid v-if="ofetot == 0">
                  <ion-row>
                    <ion-col>No hay ofertas pendientes para el cliente</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card id="planventas">
              <ion-card-header>
                <ion-icon :icon="barChartOutline"></ion-icon>
                <div class="customer-name">
                  Ventas
                </div>
              </ion-card-header>

              <ion-card-content>
                <ion-grid class="grid-situacion">

                  <ion-row class="row-situacion title">
                    <ion-col v-if="showMargin" class="ion-text-right" size-md="3" size-sm="2"><b>Venta</b></ion-col>
                    <ion-col v-if="showMargin" class="ion-text-right"><b>Margen</b></ion-col>
                    <ion-col v-if="showMargin" class="ion-text-right"><b>%</b></ion-col>

                    <ion-col v-if="!showMargin" class="ion-text-right" size-md="5" size-sm="3"><b>Venta</b></ion-col>
                  </ion-row>

                  <ion-row class="row-situacion">
                    <ion-col size="5" class="label-ventas">Plan del mes </ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin" size-md="3" size-sm="3">
                      {{ home.plmv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="!showMargin" size-md="7" size-sm="3">
                      {{ home.plmv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin">
                      {{ home.plmm }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin">
                      {{ Math.round(home.plmm / home.plmv*100, 2) }} %
                    </ion-col>
                  </ion-row>

                  <ion-row class="row-situacion">
                    <ion-col size="5" class="label-ventas">Venta mes (real)</ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin" size-md="3" size-sm="3">
                      {{ home.mv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="!showMargin" size-md="7" size-sm="3">
                      {{ home.mv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin">
                      {{ home.mm }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="!home.mv && showMargin">0 %</ion-col>
                    <ion-col class="ion-text-right" v-if="home.mv && showMargin">
                      {{ Math.round(home.mm / home.mv*100, 2) }} %
                    </ion-col>
                  </ion-row>
                  <ion-row class="row-situacion">
                    <ion-col size="5" class="label-ventas">Acumulado anual</ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin" size-md="3" size-sm="3">
                      {{ home.ytdv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="!showMargin" size-md="7" size-sm="3">
                      {{ home.ytdv }}
                    </ion-col>
                    <ion-col class="ion-text-right" v-if="showMargin">
                      {{ home.ytdmv }}
                    </ion-col>
                    <ion-col col-1 class="ion-text-right" v-if="showMargin">
                      {{ Math.round(home.ytdmv / home.ytdv*100, 2) }} %
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

      </ion-grid>
      <ion-row v-else>
        <ion-row class="spin ion-justify-content-center ">
          <ion-col class="spin ion-align-self-center" size="48">
            <ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-row>
    </ion-content>

    <ion-tab-bar slot="bottom" color="primary">
      <ion-tab-button @click="changeTab('carrito')" selected>
        <!--<i class="fak fa-regular-truck-circle-question tiempo"></i>-->
        <ion-label>Crear<br />Pedido</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="proyecto" disabled>
        <!--<i class="fas fa-sharp fa-truck-ramp-box tiempo" />-->
        <ion-label>Crear<br />Proyecto</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="visita" disabled>
        <!--<i class="fas fa-route tiempo" />-->
        <ion-label>Reportar<br />Visita</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="construdata" disabled>
        <!--<i class="fak fa-sharp-regular-house-circle-check tiempo" />-->
        <ion-label>Oport.<br />Construdata</ion-label>
      </ion-tab-button>

    </ion-tab-bar>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue"
import { returnUpBackSharp, play, personCircle, pricetagOutline, receiptOutline, barChartOutline, homeSharp } from "ionicons/icons"
import * as _ from "underscore"
import { alertController } from "@ionic/vue"

import { get_customer_docs } from '@/utilities/services/home_services'

export default defineComponent({
  name: "CustomerHomePage",
  props: ["cliente"],
  components: {},
  data: () => ({
    personCircle,
    pricetagOutline,
    returnUpBackSharp,
    receiptOutline,
    barChartOutline,
    play,
    homeSharp,
    customer: {},
    home: {},
    bloqs: {},
    docnum: {},
    pedidos: {},
    ofertas: {},
    riesgo: {},
    pedpen: 0,
    pedtot: 0,
    ofetot: 0,
    pedent: 0,
    doctot: 0,
    tipdoc: "",
    totven: 0,
    ofertasSum: {},
    meses: [],
    showMargin: false,
    showme: false,
    emulated: null
  }),

  async mounted() {
    this.meses = await this.last12months()
    await this.customerData()
  },

  async updated() {
    this.customerData()
  },
  methods: {
    async customerData() {
      const res = await this.$http.post(`/home/${this.cliente}`, {
        dev: this.$deviceUuid.uuid
      })
      const data = res.data
      console.log('customerData data', data)

      this.home = data.home ? data.home : {}
      this.bloqs = data.bloqs
      this.docnum = data.docs ? data.docs.length : 0
      this.pedidos = data.pedidos.length
      this.ofertas = data.ofertas.length
      this.riesgo = data.risk
      this.pedpen = this.pedidos == 0 ? 0 : parseInt(this.suma(data.pedidos, "pen"))
      this.pedtot = this.pedidos == 0 ? 0 : parseInt(this.suma(data.pedidos, "vnet"))
      this.ofetot = this.ofertas == 0 ? 0 : parseInt(this.suma(data.ofertas, "vnet"))

      this.ofertas_sum = _.reduce(data.ofertas, (m, v) => {
        if (m[v['dt_o'].slice(0, 6)]) {
          m[v['dt_o'].slice(0, 6)] = { val: m[v['dt_o'].slice(0, 6)]['val'] + v['vbru'], num: m[v['dt_o'].slice(0, 6)]['num'] + 1 }
        } else {
          m[v['dt_o'].slice(0, 6)] = { val: v['vbru'], num: 1 }
        }
        return m
      }, {})

      this.ofertas_sum = _.reduce(this.meses, (m, e) => {
        m[e] = { v: 0, n: 0 }
        return m
      }, {})

      _.chain(data.ofertas)
        .groupBy((e) => {
          return e["dt_o"].slice(0, 6)
        })
        .reduce((m, v, k) => {
          let a = _.reduce(
            _.pluck(v, "vnet"), (m, v) => {
              return (m += v)
            },
            0)

          let b = v.length

          let c = _.object(["v", "n"], [a, b])
          this.ofertas_sum[k] = c
        })

      this.pedent = this.pedidos == 0 ? 0 : this.pedtot - this.pedpen
      this.doctot = this.docnum == 0 ? 0 : parseInt(this.suma(data.docs, "eu"))
      let tipo = _.uniq(_.pluck(data.docs, "vp"))[0]
      this.tipdoc = tipo == "L" ? "Confirmings pdtes." : "Pagares pdtes."
      this.totven =
        (this.home.viv != undefined ? this.home.viv : 0) -
        (this.home.nov != undefined ? this.home.nov : 0)

      this.showme = true
    },
    suma(objeto, propiedad) {
      return objeto.reduce(function (memo, obj) {
        return obj[propiedad] == undefined ? memo : memo + obj[propiedad]
      }, 0)
    },
    showAction() {
      this.showMargin = !this.showMargin ? true : false
    },
    navBack() {
      this.$router.push('/comercial')
    },
    next() {
      this.$router.replace("/home/")
    },
    async verPedidos() {
      const ped_data = await get_customer_docs(this, 'pedidos', this.cliente)
      this.$router.replace({ 'name': 'pedidos', params: { datos: JSON.stringify(ped_data), tipo: 'Pedidos', origen: 'home_cliente', cliente: this.cliente } })
    },
    async verOfertas() {
      const ofe_data = await get_customer_docs(this, 'ofertas', this.cliente)
      this.$router.replace({ 'name': 'ofertas', params: { datos: JSON.stringify(ofe_data), tipo: 'Ofertas', origen: 'home_cliente', cliente: this.cliente } })
    },
    formatNum(val) {
      return val
    },
    async loadCart(prods) {
      const alert = await alertController.create({
        subHeader: 'El usuario posee un proceso de compra sin terminar',
        message: '¿Desea continuar la compra?',
        buttons: [{
          text: 'Eliminar Carro',
          role: 'cancel',
          handler: () => {
            this.handlerMessage = 'Alert canceled';
            this.$functions.delSavedCart(this.customer)
            this.$store.commit("emptyPriceResume");
          },
        },
        {
          text: 'Continuar',
          role: 'confirm',
          handler: () => {
            prods.forEach((item) => {
              this.$store.commit("setProductCart", item)
            })
            Object.keys(prods).forEach((key) => {
              this.totalItems += (prods[key].qx / prods[key].us) * prods[key].pr

              this.$store.commit("setPriceResume", { 'total': this.totalItems })
              this.$store.commit("setPriceResume", { 'iva': (this.totalItems * 0.16) })
              this.$store.commit("setPriceResume", { 'total_iva': this.totalItems + (this.totalItems * 0.16) })
            });
            this.handlerMessage = 'Alert confirmed'
          },
        }]
      })
      await alert.present()
      await alert.onDidDismiss()
    },
    async last12months() {
      var d = new Date()
      var l_meses = []
      console.log('last12months d', d.getMonth())
      for (let i = 0; i <= 5; i++) {
        l_meses.push(d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, '0'))
        d.setMonth(d.getMonth() - 1)
      }
      return l_meses
    },
    changeTab(ruta) {
      this.$router.replace({ 'name': ruta, params: { origen: 'home_cliente', cliente: this.cliente, nomcli: this.home.n } })
    }
  }
})
</script>

<style scoped>
.after-header {
  background-color: #ff0000 !important;
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
}

ion-header ion-toolbar {
  padding: 0 60px;
}

.customer-resume>ion-grid {
  width: 80%;
}

ion-card {
  height: 100%;
}

ion-card-header {
  color: #203360;
  font-size: 24px;
  font-weight: bolder;
}

.customer-data {
  color: #203360;
}

.circulante {
  font-size: 20px;
  font-weight: bolder;
}

.customer-data span {
  font-size: 12px;
  color: #737373;
}

ion-card-header ion-icon {
  font-size: 32px;
  position: absolute;
  margin-top: -3px
}

.customer-name {
  margin-left: 40px;
  display: inline-block;
}

.button-situacion {
  font-size: 16px;
  margin-top: 1px;
}

.grid-situacion {
  width: 100%;
  color: #203360;
}

.row-situacion:nth-child(odd) {
  background: #f7f7f7 !important;
  color: #203360;
}

.row-situacion.title {
  font-weight: bold;
  font-size: 12px;
  background: transparent !important;
}

.row-situacion.total {
  background: transparent !important;
}

.row-situacion.total .ion-text-right {
  font-size: 20px;
  font-weight: bold;
}

.meses b {
  background: #33a2df;
  color: #FFF;
  font-size: 10px !important;
  padding: 1px 10px 3px;
  border-radius: 10px;
  margin-left: 10px;
  height: 17px
}

.meses ion-col:first-child {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

@media (max-width: 1280px) {
  .customer-name {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .customer-resume>ion-grid {
    width: 100%;
  }

  .card-content-md {
    font-size: 12px;
    line-height: 1.5;
  }

  .toolbar-title {
    text-align: left;
  }

  ion-card-content {
    font-size: 12px;
  }

  ion-grid {
    width: 100%;
  }

  ion-grid ion-row ion-col {
    padding: 5px 4px 5px 0;
  }

  ion-header ion-toolbar {
    padding: 0 5px;
  }

  ion-button {
    font-size: 12px;
  }

  .row-situacion .ion-text-right {
    font-size: 12px;
    font-weight: bold;
  }

  .customer-name {
    font-size: 16px;
  }

  ion-card-header {
    padding-bottom: 0px;
    padding-top: 7px;
    padding-left: 8px;
  }

  ion-card-header ion-icon {
    font-size: 22px;
    margin-top: 3px;
    margin-left: 5px;
  }

  ion-card-content ion-grid {
    padding-top: 0px;
  }

  ion-card-content ion-grid br {
    display: none;
  }

  .customer-data span {
    font-weight: bolder;
    font-size: 13px;
  }

  .customer-data {
    font-size: 13px;
  }

  ion-card-content ion-grid ion-row ion-col {
    padding-bottom: 0px;
  }

  .izquierda {
    text-align: left;
  }

  .circulante {
    font-size: 14px;
  }

  .head-icon {
    display: none;
  }

  ion-card {
    margin: 0;
  }

  #ofertas {
    height: 100%;
  }

  #ofertas ion-card-content {
    padding: 0 3px 10px 5px;
  }

  #pedidos .customer-name,
  #ofertas .customer-name {
    margin-left: 0;
    width: 100%;
  }

  #pedidos ion-button,
  #ofertas ion-button {
    margin-top: -24px !important;
    margin-right: -10px;
    height: 24px;
  }

  .button-situacion {
    margin-left: 0px;
  }

  ion-card-content {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  ion-card-content ion-grid {
    padding-left: 0;
    padding-right: 0;
  }

  .row-situacion.total .ion-text-right {
    font-size: 14px;
    font-weight: bold;
  }

  ion-toolbar {
    width: 100%;
  }

  .resume-title {
    font-size: 15px;
    padding-inline-start: 50px;
    padding-inline-end: 10px;
    text-align: left;
  }

  #pedidos {
    height: 100%;
  }

  .meses b {
    padding: 1px 5px 3px;
    margin-left: 2px !important;
  }

  .row-situacion.title {
    width: 100%;
  }

  .label-months,
  .label-situacion {
    font-size: 12px;
  }
}
</style>
