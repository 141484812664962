<template>
    <div>
    </div>
</template>


<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: "FontAwesomeLibraryComponent",
    created() {

        var scripts = [`https://kit.fontawesome.com/c202749e04.js?`];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            tag.setAttribute("crossorigin", 'anonymus')
            document.head.appendChild(tag);
        });
    }
})
</script>
 