<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary" class="ion-justify-content-start ion-align-items-start">
                <ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>

                <ion-title>
                    <ion-row class="ion-justify-content-start ion-align-items-start">
                        <ion-col size="40" class="ion-float-left izquierda">
                            Recobro
                        </ion-col>
                    </ion-row>
                </ion-title>
            </ion-toolbar>
        </ion-header>


        <ion-content v-if="showme">
            <ion-row class="header">
                <ion-col class="list-title">
                    <ion-button color="primary" @click="goBack()">
                        <ion-icon :icon="arrowBackOutline" padding></ion-icon>
                    </ion-button>
                    <h3>Cartera (Recobro)</h3>
                </ion-col>
            </ion-row>

            <ion-row>                
                <ion-col>
                    <DataTable :value="cartera" 
                        responsiveLayout="scroll" sortMode="single"
                        sortField="n" :sortOrder="1" v-model:filters="filters1" :globalFilterFields="['n', 'ni', 'g']">

                        <template #empty>
                            No customers found.
                        </template>

                        <template #loading>
                            Loading customers data. Please wait.
                        </template>

                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1['cad'].value" :placeholder="'Filtrar Cliente'"
                                        class="input-search" />
                                </span>
                            </div>
                        </template>

                        <Column v-for="(p, i) in cols" :key="'c'+i" :field="p.campo" :header="p.header" />

                    </DataTable>
                </ion-col>

            </ion-row>
        </ion-content>
    </ion-page>
</template>
    
    
<script>

import { defineComponent } from 'vue'
import { arrowBackOutline, homeSharp } from "ionicons/icons"
import { get_cartera } from "@/utilities/services/home_services"
import { FilterMatchMode } from "primevue/api"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import InputText from "primevue/inputtext"
import eventBus from "@/event-bus"


export default defineComponent({
    name: 'CarteraPage',
    components: { DataTable, Column, InputText },
    props: ['origen'],
    data() {
        return {
            homeSharp, arrowBackOutline,
            expandedRowGroups: null,
            showme: false,            
            gestoras: [],
            cartera: undefined,
            cadenas: [],
            cadena: undefined,
            limite: 50,
            columnas: [
                { campo: 'ni', header: 'Nombre', tam: 1, width: 0.75 },
                { campo: 't30', header: '0-30', tam: 1, width: 0.75 },
                { campo: 't60', header: '31-60', tam: 2, width: 0.75 },
                { campo: 't90', header: '61-90', tam: 2, width: 0.75 },
                { campo: 't120', header: '91-120', tam: 2, width: 0.75 },
                { campo: 't180', header: '121-180', tam: 2, width: 0.75 },
                { campo: 't180x', header: '180+', tam: 2, width: 0.75 },
                { campo: 'ven', header: 'Vencido', tam: 1, width: 0.75 },
                { campo: 'nov', header: 'No Vencido', tam: 1, width: 0.75 },
                { campo: 'ins', header: 'Insol.', tam: 1, width: 0.75 },
                { campo: 'abo', header: 'Abogado', tam: 2, width: 0.75 },
                { campo: 'g', header: 'Gestor', tam: 2, width: 0.75 }
            ],
            cols: [],
            filters1: {
                cad: { value: null, matchMode: FilterMatchMode.CONTAINS },
                ni: { value: null, matchMode: FilterMatchMode.CONTAINS },
                g: { value: null, matchMode: FilterMatchMode.CONTAINS }
            }
        }
    },
    computed: {
        /*cols: function() {
            console.log('computed cols')
            return this.columnas.filter( c => { return c.tam <= this.device.tamanyo })
        }*/
    },
    async mounted() {
        await this.fetchCartera()
        this.cols = this.columnas.filter( c => { return c.tam <= this.device.tamanyo })
        console.log('this.cols', this.cols)
        
        eventBus().emitter.on('device', (sz) => {
            this.device = sz
            this.cols = this.columnas.filter( c => { return c.tam <= this.device.tamanyo })

            console.log('sz', this.device)
            console.log('cols', this.cols)
        })
    },
    methods: {
        async fetchCartera() {
            try {
                this.device = this.$device
                this.cartera = await get_cartera(this)
                this.gestoras = Array.from(new Set(this.cartera.map((v) => { return v.g })))
                this.cadenas = Array.from(new Set(this.cartera.map((v) => { return { 'n': v.cad, 'v': v.cad } })))
                this.showme = true                
            }
            catch (ex) {
                console.log('ex', ex)
                return null
            }
        },
        calculateTotal(tipo, grupo, campo) {
            let total = 0
            if (tipo == 'cad') {
                total = this.cartera.filter(e => { return e.cad == grupo }).reduce((m, v) => { return m = m + parseFloat(v[campo]) }, 0)
            } else {
                total = this.cartera.filter(e => { return e.g == grupo }).reduce((m, v) => { return m = m + parseFloat(v[campo]) }, 0)
            }
            return new Intl.NumberFormat().format(total)
        },
        goBack() {
            this.$router.replace({ 'name': this.origen })
        }
    }
})
</script>
    
  
<style scoped>

ion-title {
    padding-right: 4.5rem !important;
}

.list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-title h3,
.p-input-icon-left{
    width: 100%;
}

@media (max-width: 900px) {

    ion-grid {
        width: 100%;
        padding: 20px 0 0 0;
        margin: 0 auto;
        font-size: 14px;
    }

    .header {
        background-color: #33a2df;
        color: #FFFFFF;
    }

    .header h3 {
        color: #ffffff;
        font-size: 16px;
        padding-bottom: 0px;
        margin-top: 8px;
    }

    .input-search {
        width: 100%;
        display: inline-block;
        height: 30px;
        padding-left: 35px;
    }

    ion-button {
        font-size: 10px;
    }

    ion-item {
        font-size: 14px;
    }

    .pi::before {
        font-size: 12px;
    }

}
</style>
