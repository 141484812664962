<template>
    <ion-page :style="fontSize">
        <ion-header>
            <ion-toolbar color="primary">
                <!--<ion-buttons slot="start">
                    <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
                        <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                    </ion-button>
                </ion-buttons>
                -->
                <ion-menu content-id="main" class="right-menu" :disabled="menuDisabled">
                    <LeftMenu />
                </ion-menu>

                <ion-title class="derecha">
                    Mobiges<br />
                    <ion-note>
                        <small>by GES</small>
                    </ion-note>
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content v-if="device">
            <ion-row v-if="loading" class="spin ion-justify-content-center ">
                <ion-col class="spin ion-align-self-center" size="12">
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>

            <ion-row class="ion-align-items-top selectores grande" v-else>
                <ion-col>
                    <DropDown v-model="reporting_actual" :options="reporting" optionLabel="titulo" class="selector"
                        :filter="true" placeholder="Reports disponibles" @change="actualizaMenus()"
                        bind:key="reporting_actual" />
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <DropDown v-model="report_actual" :options="reports" optionLabel="titulo"
                        class="selector" :filter="true" placeholder="Agrupar por" bind:key="agrupacion_actual" />
                </ion-col>
                <ion-col>
                    <DropDown v-model="agrupacion_actual" :options="agrupaciones_disponibles" optionLabel="titulo"
                        class="selector" :filter="true" placeholder="Agrupar por" bind:key="agrupacion_actual" />
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <IonButton @click="refrescar()">Refrescar</IonButton>
                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>
 
<script>

import {
    IonPage, IonContent, IonRow, IonCol, IonSpinner, IonButton
} from '@ionic/vue'

import { wjFormat } from '@grapecity/wijmo.vue2.core'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { chain, each, map } from 'underscore'


import { get_reporting_data } from '@/utilities/services/reporting_services'

import eventBus from "@/event-bus"
export default defineComponent({
    name: "NewRac",
    components: {
        IonPage, IonContent, IonSpinner,
        IonRow, IonCol, IonButton
    },
    props: ['group1', 'group2'],
    computed: {
        fontSize() {
            window.fake_console('Clasificaciones', `--texto-size : ${9 + (this.device.tamanyo)}px`)
            return `--texto-size : ${9 + (this.device.tamanyo)}px;`
        },
        titulos() {
            const totales = this.columnas.reduce((m, v) => { return m + v['width'] }, 0)
            const titulos = chain(this.columnas).pluck('grupo').uniq().reduce((m, v) => { m[v] = 0; return m }, {}).value()
            each(this.columnas, (v) => { titulos[v['grupo']] += v['width'] })
            const resultado = map(titulos, (v, k) => { return { 'gr': k, 'sz': (v / totales * 100) } })
            return resultado
        },
        columnas() {
            const cols = [
                { "grupo": "Etiqueta", "col": "nom", "header": " ", "width": 2.5, "show": true, "level": 0 },

                { "grupo": "Resto Ultimos 12 Meses", "col": "vb12", "perc": "mb12", "head_key": "m12", "format": "n0", "width": 0.75, "align": "right", show: true, "izq": true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb11", "perc": "mb11", "head_key": "m11", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb10", "perc": "mb10", "head_key": "m10", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb09", "perc": "mb09", "head_key": "m09", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb08", "perc": "mb08", "head_key": "m08", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb07", "perc": "mb07", "head_key": "m07", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb06", "perc": "mb06", "head_key": "m06", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb05", "perc": "mb05", "head_key": "m05", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb04", "perc": "mb04", "head_key": "m04", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb03", "perc": "mb03", "head_key": "m03", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                { "grupo": "Resto Ultimos 12 Meses", "col": "vb02", "perc": "mb02", "head_key": "m02", "format": "n0", "width": 0.75, "align": "right", show: true, "level": 3 },
                //{ "grupo" : "Resto Ultimos 12 Meses" ,   "col": "vb01" , "perc" : "mb01" , "head_key": "m01" , "format" : "n0", "width": 0.9,"align": "right",  show : true },
                { "grupo": "Ultimo Mes", "col": "vb01", "perc": "mb01", "head_key": "m01", "format": "n0", "width": 0.9, "align": "right", show: true, "izq": true, "level": 2 },
                { "grupo": "Ultimo Mes", "col": "vb13", "perc": "mb13", "head_key": "m13", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 2 },
                { "grupo": "Ultimo Mes", "col": "dv01", "perc": "dm01", "header": "Delta", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 2 },
                { "grupo": "Mes en Curso", "col": "mv", "perc": "mm", "header": "Actual", "format": "n0", "width": 0.9, "align": "right", show: true, "izq": true, "level": 0 },
                { "grupo": "Mes en Curso", "col": "vb99", "perc": "mb99", "header": "Proyect.", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 1 },
                { "grupo": "Mes en Curso", "col": "vb12", "perc": "mb12", "head_key": "m12", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 0 },
                { "grupo": "Mes en Curso", "col": "dv00", "perc": "dm00", "header": "Delta", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 1 },

                { "grupo": "YTD", "col": "ytd_v", "perc": "ytd_mb", "head_key": "m01", "format": "n0", "width": 0.9, "align": "right", show: true, "izq": true, "level": 0 },
                { "grupo": "Periodos 12 meses", "col": "ly1v", "perc": "ly1m", "head_key": "ly1", "format": "n0", "width": 0.9, "align": "right", show: true, "izq": true, "level": 2 },
                { "grupo": "Periodos 12 meses", "col": "lyv", "perc": "lym", "head_key": "ly0", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 2 },
                { "grupo": "Periodos 12 meses", "col": "ltm_v", "perc": "ltm_mb", "header": "LTM", "format": "n0", "width": 0.9, "align": "right", show: true, "level": 1 },

                { "grupo": "Stock", "col": "st", "header": "Valor", "format": "n0", "width": 0.9, "align": "right", show: true, "izq": true, "level": 0 },
                { "grupo": "Stock", "col": "rotacion", "header": "Rotac.", "format": "n0", "width": 0.7, "align": "right", show: true, "level": 0 }
            ]
            const nivel = { 'sm': 1, 'md': 2, 'lg': 3, 'xl': 4 }[this.device.tipo]
            return cols.filter(x => x.show && x.level < nivel)
        },
        numcols() {
            return chain(this.grupos).filter((v, k) => /group/.test(k)).values().compact().value().length - 1
        }
    },
    data() {
        return {
            reporting: [
                { "id": 1, "titulo": "Mes en curso / Clientes", "group1": "cliente", "group2": null },
                { "id": 2, "titulo": "Mes en curso / Globales", "group1": "cliente", "group2": null },
                { "id": 3, "titulo": "Mes en curso / Marcas", "group1": "cliente", "group2": null },

                { "id": 1, "titulo": "Meses cerrados / Clientes", "group1": "cliente", "group2": null },
                { "id": 2, "titulo": "Meses cerrados / Globales", "group1": "cliente", "group2": null },
                { "id": 3, "titulo": "Meses cerrados / Marcas", "group1": "cliente", "group2": null }
            ],
            reports: [
                { "id": 1, "titulo": "Detalle mes actual" },
                { "id": 2, "titulo": "Kpis seguimiento" },
                { "id": 3, "titulo": "Mes a mes"}
            ],
            agrupaciones: [
                { "id": 1, "tipo": "Mes en curso", "titulo": "Clientes sin agrupar" },
                { "id": 1, "titulo": "PdV y NIF" },
                { "id": 1, "titulo": "PdV y canal" },
                { "id": 1, "titulo": "Cadena" },

                { "id": 2, "titulo": "Globales sin agrupar" },
                { "id": 2, "titulo": "Zona y comercial" },
                { "id": 2, "titulo": "PdV y canal" },
                { "id": 2, "titulo": "Cadena" },

                { "id": 3, "titulo": "Marcas sin agrupar" },
                { "id": 3, "titulo": "Categoria y fabricante" },
                { "id": 3, "titulo": "Categoria y product line" },
                { "id": 3, "titulo": "Fabricante" }
            ],
            reporting_actual: undefined,
            report_actual: undefined,
            agrupacion_actual: undefined,
            grupos: { group1: this.group1, group2: this.group2 },

            /** Valores seleccionados para cada filtro, los posibles son los que estan en filters */
            SelectedFilters: undefined,
            etiquetas: {
                'marcas': 'Marcas', 'fabricantes': 'Fabricantes',
                'pdvs': 'Pdvs', 'zonas': 'Zonas',
                'plines': 'Product Lines', 'categorias': 'Categorias'
            },
            colores_badges: {
                'marcas': 'secondary', 'fabricantes': 'danger',
                'pdvs': 'primary', 'plines': 'dark', 'zonas': 'light', 'categorias': 'warning'
            },
            filtros: undefined,
            payload: {},
            campos_ventas: ["ltm_v", "ly1v", "lyv", "mv", "dv00", "dv01", "vb99", "vb01", "vb02", "vb03", "vb04", "vb05", "vb06", "vb07", "vb08", "vb09", "vb10", "vb11", "vb12", "vb13", "ytd_v"],
            separador: new RegExp(/[ ]+/),
            /** Variable que controla el spinner de carga  */
            loading: false,
            /** Variable de control para actuar en mount/update */
            ruta: undefined,
            fetchUrl: undefined,

            /** variable de control del debounce (diferimiento ) */
            debounce: null,

            /**
             * Variable donde estan los datos que efectivamente se ven en la tabla 
             * una vez aplicados todos los filtros 
             */
            datos: undefined,
            parametros: undefined,

            /**
             * Variable donde estan los datos que efectivamente se ven en la tabla 
             * una vez aplicados todos los filtros 
             */
            filtrados: undefined,
            disponibles: undefined,

            /** 
             * Termino de busqueda que introducimos en el filtro libre 
             */
            termino: undefined,

            /** 
             * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
             * para responder a los cambios de tamaño de ventana, orientacion...  
             */
            device: this.$device
        }
    },
    async mounted() {
        const route = useRoute()
        this.ruta = route.fullPath
        this.reporting_actual = this.reporting[0]
        this.report_actual = this.reports[0]
        this.agrupacion_actual = this.agrupaciones[0]
        await this.procesoInicial()
    },
    methods: {
        formatNumber: function (value, format) {
            return wjFormat(value, format)
        },
        async procesoInicial() {
            this.device = this.$device
            eventBus().emitter.on('device', (sz) => { this.device = sz })
            this.fetchData()
        },
        async fetchData() {
            console.log('fetch datos!')
            this.get_reporting_data()
        },
        async cambio_report() {
            console.log('cambio report')
        },
        async refrescar() {
            console.log('refrescamos la vista')
        },
        actualizaMenus() {
            this.agrupaciones_disponibles = this.agrupaciones.filter(x => { return x.id == this.reporting_actual.id })
            this.$forceUpdate()
        },
        async get_reporting_data() {
            const self = this
            console.log('vamos a por los datos')
            this.datos = await get_reporting_data(self)
            console.log('this.get_reporting_data', this.datos)
        }
    }

})
</script>
 
<style scoped>
ion-badge {
    margin-top: 6px;
    margin-right: 6px;
    padding-top: 0px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 4px;
}

ion-icon.badge {
    padding-top: 4px;
    font-size: 1.25rem;
}

ion-row.completa {
    width: 100% !important;
}

i.ordenes {
    color: var(--ion-color-primary) !important;
    font-size: 2rem !important;
}

.distinto,
.distinto>* {
    color: var(--ion-color-danger) !important;
}

ion-button.refresco {
    margin-right: 0px !important;
}

div.p-dropdown.selector_multi {
    width: 100% !important;
    padding-top: 3.5px !important;
    padding-bottom: 2.5px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    max-height: 39px !important;
    background-color: var(--ion-color-light);
    font-weight: bold !important;
    color: var(--ion-color-danger) !important;
}

div .p-dropdown {
    max-height: 32px !important;
}

div .p-dropdown>span.p-dropdown-label {
    padding-top: 7px;
    padding-bottom: 8px;
}

div.p-dropdown.selector {
    width: 100% !important;
    padding-top: 3.5px !important;
    padding-bottom: 2.5px !important;
    max-height: 39px !important;
    background-color: var(--ion-color-light);
    font-weight: bold !important;
    color: var(--ion-color-danger) !important;
}
</style>
 
<style scoped lang="scss">
@import '@/theme/listas.scss';
</style>
