import eventBus from "@/event-bus";
import {
    esArticulo, esEan, esSalida, esEntrada, esUbicacion, teclasFuncion,
    transformSchneiderBrand
} from '@/utilities/global-constants';
import { openAlertaV2, openAlertaBinariaInput } from '@/utilities/services/alert-utilities'
import { chain, pick, difference, extend, intersection, reduce, partition, every } from 'underscore';
import SearchProductComponent from '@/components/search/search-product-component.vue'
import { modalController } from '@ionic/vue';
import { openAlertaUnidadesCarrito } from '@/utilities/services/alert-utilities';
import { add2MostradorStorage } from '@/utilities/storage/storage-utilities-main'

const consola = function () { void (0) };
let contador = 0
var articulo = ''
let bindLectorArticulos, eans_pdv, ubicaciones_pdv, catalogo_pdv, lotes_pdv, stock_pdv, pdv, eans_articulo, codigos_bobinas,
    RegexBobinasPdv, totales, hechos, stock_total_con_cables


const reverseEans = function () {
    return reduce(eans_pdv, (m, v, k) => {
        const ean = String(k).padStart(15, '0')
        const resultado = v.reduce((mm, vv) => {
            mm[vv] = (mm[vv] || '') + `${ean} `;
            return mm
        }, m)
        return resultado
    }, {})
}

const DatosCatalogoSku = function (codmar, artpro) {
    const sku = `${codmar}.${artpro}`
    return catalogo_pdv[sku] || {}
}

const DetallesReferencia = function (sku) {
    window.fake_console(sku, catalogo_pdv[sku])
    const elementos_sku = stock_pdv.filter(v => v.sku == sku) || []
    /** incorporarmos las ubicaciones secundarias */
    const respuesta = elementos_sku.reduce((m, v) => {
        const refe = `${v.sku}.${pdv}.${v.codsuc}.${!v.lote ? 'N' : v.lote}`
        v['ubi2'] = ubicaciones_pdv[refe] != undefined ? JSON.parse(ubicaciones_pdv[refe]['ubi2']) : []
        m.push(v)
        return m
    }, []
    )
    window.fake_console(respuesta)
    return respuesta
}

const ArticulosLote = function (lote) {
    return stock_pdv.filter(x => (x.lote || '').toLowerCase() == lote.toLowerCase()) || []
}

/**ActualizarUbicacionesLocales(payload.codsuc, payload.sku, payload.lote, product.ubi, product.ubi2)
*/
const ActualizarUbicacionesLocales = function (codsuc, sku, lote, ubi, ubi2) {
    window.fake_console(ubi2)
    const ubisec = JSON.stringify(ubi2)
    const clave = `${sku}.${pdv}.${codsuc}.${!lote ? 'N' : lote}`
    window.fake_console(ubisec)
    ubicaciones_pdv[clave].ubi = ubi;
    ubicaciones_pdv[clave].ubi2 = ubisec;
    window.fake_console(clave, ubicaciones_pdv[clave])
    construirStock()
}

const construirStock = function () {
    stock_pdv = reduce(lotes_pdv, (m, v, k) => {
        const producto = catalogo_pdv[k];
        if (producto != undefined) {
            const tags_template = ['sku', 'ManufacturerName', 'shortDescription', 'refpro'].reduce((mm, vv) => {
                return mm += `${(producto[vv] || '').toLowerCase()} `
            }, '')
            const mem = v.map(vv => {
                let tags = tags_template + `${(vv['lote'] || '').toLowerCase()} `
                const clau = `${k}.${pdv}.${vv.codsuc}.${!vv.lote ? 'N' : vv.lote}`
                if (eans_articulo[k]) {
                    tags += `${eans_articulo[k]} `
                }
                if (ubicaciones_pdv[clau]) {
                    const { ubi, ubi2 } = ubicaciones_pdv[clau]
                    vv['ubi'] = ubi
                    if (ubi) { tags += `"${(ubi || '').toLowerCase()}" ` }
                    if (ubi2 && !vv['lote']) {
                        const ubisec = JSON.parse(ubi2).map(u => `${(u || '').toLowerCase()}`).join(' ')
                        tags += `"${(ubisec || '').toLowerCase()}" `
                    }
                }
                return Object.assign(vv, catalogo_pdv[k], { tags })
            })
            m.push(...mem);
        }
        return m
    }, [])
    /** 
     * totales = stock_pdv.length
     * hechos = stock_pdv.filter(v=>v.ubi!=undefined).length
    */
    const cables_wip = stock_pdv.reduce((m, v) => {
        if (v.lote && v.sku.slice(0, 3) != 'BOB') {
            const sk = v.sku
            const inicializa = { 'cables': true, 'stoalm': 0, 'ubi': undefined, lote: undefined, hay_stock: false, dispo: 0 }
            m[sk] = Object.assign({}, v, inicializa)
        }
        return m
    }, {})

    stock_total_con_cables = stock_pdv.concat(Object.values(cables_wip))
    totales = stock_pdv.filter(v => (v.stoalm || 0) > 0).length
    hechos = stock_pdv.filter(v => v.ubi != undefined && (v.stoalm || 0) > 0).length
    console.log(totales, hechos)
}

eventBus().emitter.on('memory-data', (dicts) => {
    try {
        window.miconsola('Monto las variables en memoria')
        eans_pdv = dicts.eans_pdv;
        pdv = dicts.pdv
        ubicaciones_pdv = dicts.ubicaciones_pdv;
        window.fake_console('L122 :', ubicaciones_pdv['MRG.A9R60240.001.00.N'])
        catalogo_pdv = dicts.catalogo_pdv
        RegexBobinasPdv = new RegExp(`^(${dicts.bobinas_pdv.join('|')})`, 'i')
        consola(RegexBobinasPdv)
        lotes_pdv = dicts.lotes_pdv
        codigos_bobinas = dicts.codigos_bobinas
        eans_articulo = reverseEans()

        /** Variantes de stock del pdv para paginas que requieran filtro simple sin ir a solr. */
        construirStock()
        consola('EANS TOTALES : ', Object.keys(eans_pdv).length, ' Muestra :', Object.keys(eans_pdv).slice(0, 10))
        window.fake_console(ubicaciones_pdv)
        consola('ubicaciones : ', JSON.stringify(ubicaciones_pdv).slice(0, 100))
    }
    catch (ex) {
        window.fake_console('ERROR EN scan-sku L58: 0', ex)
        window.fake_console('L128 scan-sku   :', ex)
        alert(JSON.stringify(ex))
    }
});

const resetResaltados = async function () {
    await stock_pdv.reduce((m, v) => {
        v['resaltado'] = false;
        return m += 1
    }, 0)
    return
}


const stockPdvFiltrado = function (termino_original, tipo_busqueda, pendientes) {
    consola('lanzando busqueda.....')
    const terminos = transformSchneiderBrand(termino_original)
    //window.fake_console(terminos)
    consola(terminos, tipo_busqueda, pendientes)
    if (terminos === false) {
        if (pendientes != 1) {
            return stock_pdv || []
        }
        else {
            return stock_pdv.filter(v => !v.ubi && (v.stoalm || 0) > 0) || []
        }
    }
    else if (tipo_busqueda == 'exacta') {
        consola('busqueda exacta ', terminos)
        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/, '').replace(/ +/g, ' ')})`, 'i')
        if (pendientes != 1) {
            return stock_pdv.filter(v => primarySearch.test(v['tags'])) || []
        }
        else {
            return stock_pdv.filter(v => (primarySearch.test(v['tags']) && !v.ubi) || [])

        }

    }
    else {
        consola('BUSQUEDA aproximada')
        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/, '').replace(/ +/g, '.*')})`, 'i')
        const secondarySearch = terminos.toLowerCase().replace(/ +$/, '').split(' ').map(v => new RegExp(`${v}`, 'i'))
        const [primario, resto] = pendientes != 1 ?
            partition(stock_pdv, v => primarySearch.test(v['tags']))
            :
            partition(stock_pdv, v => (primarySearch.test(v['tags']) && !v.ubi))

        //en segundo lugar desordenados 
        const secundario = pendientes != 1 ?
            resto.filter(v => every(secondarySearch, t => t.test(v['tags'])))
            :
            resto.filter(v => every(secondarySearch, t => t.test(v['tags']) && !v.ubi))
        return primario.concat(secundario) || []
    }
}


const stockMostrador = function (termino_original) {
    /** Abrimos la función para que devuelva todo el stock, este o no disponible  */
    consola('lanzando busqueda.....')
    const terminos = transformSchneiderBrand(termino_original)
    if (terminos === false) {
        return stock_pdv || []
    }
    else {
        consola('BUSQUEDA aproximada')
        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/, '').replace(/ +/g, '.*')})`, 'i')
        window.fake_console(primarySearch)
        const secondarySearch = terminos.toLowerCase().replace(/ +$/, '').split(' ').map(v => new RegExp(`${v}`, 'i'))
        window.fake_console(secondarySearch)
        const [primario, resto] = partition(stock_total_con_cables, v => (primarySearch.test(v['tags'])))

        //en segundo lugar desordenados         
        const secundario = resto.filter(v => every(secondarySearch, t => t.test(v['tags'])))
        return primario.concat(secundario) || []
    }
}





/** 
 * Funcion de escaneado de articulos  
 * Activa el listener para escuchar los eventos del escaner en la lectura de articulos 
 * Evalua el resultado 
 *      - NO es articulo : Muestra alerta, y pide seguir escaneando o cancelar (todo a estado original)
 *      - ES articulo : 
 *          - Verifica si hay 'variantes' (sucursales o lotes y nos pide que elijamos el correcto) 
 * 
 * */

/** Listener, tiene que estar con la función para poder escupir datos a la variables compartida  */
const scanListenerArticulos = function (elId, e) {
    if (e.keyCode == 13) {
        e.preventDefault();
        e.stopPropagation();
        window.fake_console(articulo)
        eventBus().emitter.emit(`lectura:${elId}`, articulo)
        articulo = ''
    }
    else {
        //articulo+= e.keyCode == 32 ? ' ' : e.keyCode!=16 ? e.key : '';
        articulo += e.keyCode == 32 ? ' ' : teclasFuncion.indexOf(e.keyCode) == -1 ? e.key : '';
    }
}



/*  MODAL LECTURA CON ALTERNATIVAS  */
const CierreBuscadorSinResultados = async function (articulo, callback) {
    await openAlertaBinariaInput('No encontramos nada...',
        `La lectura del escaner  ${articulo}, no ha producido ningun resultado, 
         parece que este articulo no existe. \n
         Si conoces la marca y referencia escribelas a continuación e intentaremos encontralos.. \n
         Probamos un último intento o cancelamos la busqueda?`,
        'Cancelar', 'Seguimos',
        [
            { 'name': 'marca', 'placeholder': 'Codigo marca' },
            { 'name': 'articulo', 'placeholder': 'Codigo articulo' }
        ],
        async (res, data) => {
            consola(res)
            callback(res, data)
        }
    )
}

/* Alerta ubicación Erronea */
const ArticuloErroneo = async function (articulo) {
    await openAlertaV2(
        'Lectura de producto errónea',
        `La lectura "${articulo}" no corresponde a un articulo o EAN.\n 
                 Lee un nuevo ean o articulo  para continuar `
    )
    return false
}

/* Alerta ubicación Erronea */
const ArticuloInexistentePdv = async function (articulo) {
    await openAlertaV2(
        'Producto inexistente en el pdv',
        `El articulo "${articulo}" no figura entre los dados de alta en tu pdv.\n 
                 Por favor, si hay stock verifica en gestión que exista la referencia`
    )
    return false
}



/** monta el array con los datos de productos y lotes 
 * un registro por cada posible variante sku, suc, lot 
 */
const montarListaSkusLotes = async function (skus) {
    const catalogo_skus = pick(catalogo_pdv, skus)
    const lotes_skus = pick(lotes_pdv, skus)
    const no_encontrados = difference(skus, Object.keys(lotes_skus))
    const encontrados = intersection(skus, Object.keys(lotes_skus))
    consola({ catalogo_skus, lotes_skus, no_encontrados })
    const alternativas = encontrados.reduce((m, s) => {
        const lot = chain(lotes_skus[s]).map(e => { return extend(e, catalogo_skus[s]) }).value()
        m.push(...lot)
        return m
    }, [])
    consola({ status: true, alternativas, no_encontrados })
    return { status: true, alternativas, no_encontrados }
}

/**
 * Intenta resolver localmente una lectura de escaner 
 * comparando con eans  (no compara con catalog, si es un sku lo deja pasar)
 * Si hay  skus, la manda a montar la lista detallada con al función anterior 'montarListaSkusLotes'
 * Esa función es la que dirime si tenemos o no el sku 
    * POSIBLE EVOLUCIÓN POSTERIOR
    * ¿y si estas convencido de que esta bien te deja ir al buscador ? 
 */

const ResolverLecturaLocal = async function (x, tipoLectura) {
    consola('entrada: ', x, tipoLectura)
    window.miconsola(x, tipoLectura == 'ean' ? (eans_pdv[x] || []) : [])
    let skus = tipoLectura == 'sku' ? [transformSchneiderBrand(x)] : tipoLectura == 'ean' ? (eans_pdv[x] || []) : []
    consola(skus)
    if (skus.length == 0) {
        /* no hemos resuelto el articulo, hay que buscar */
        consola('L105 : entro en skus.length == 0 ')
        return { status: false, alternativas: undefined, no_encontrados: undefined, resultado_busqueda: x }
    }
    else {
        /* montamos array con datos de los articulos y los lotes para que el usuario elija e correcto  */
        consola('L110 , tengo resultados, voy a montar la lista ', JSON.stringify(skus))
        return await montarListaSkusLotes(skus)
    }
}

const BuscarArticulos = async function (termino, elId) {
    consola('L120 : ', elId)
    const busqueda = termino || ''
    const modal = await modalController
        .create({
            component: SearchProductComponent,
            cssClass: 'custom-recommendations-modal',
            componentProps: { search: busqueda, isEan: esEan.test(termino) },
        })
    /*
     */
    modal.onDidDismiss().then(() => {
        consola('dismiss sin seleccion, seguimos con escaner activo', elId)
        eventBus().emitter.emit('cerrar-busqueda', undefined)
    })

    eventBus().emitter.once('cerrar-busqueda', async (el, ean_buscado_en_google) => {
        consola('CIERRO BUSQUEDA')
        modal.dismiss();
        consola(el)
        articulo = ''
        if (el == undefined) {
            consola('cierro con dismis exterior ')
            eventBus().emitter.emit(`resultados:${elId}`, { estado: false })
        }
        else if (el === busqueda) {
            /** devolvemos el control para ir a la busqueda manual  */
            eventBus().emitter.emit(`resultados:${elId}`, {
                estado: true, lotes: [],
                accion: 'filtrar-pdv', texto: undefined, ean_sin_resultados: el
            })
        }
        else {
            /**
             * La busqueda a devuelto algo con sentido, lo procesamos 
             */

            const { status, alternativas, no_encontrados, resultado_busqueda } = await ResolverLecturaLocal(el, 'sku')
            if (status == true || (alternativas || []).length > 0) {
                eventBus().emitter.emit(`resultados:${elId}`, { estado: true, lotes: alternativas, no_encontrados, ean_buscado_en_google })
                return
            }
            else if (status === false && el != undefined) {
                /** el resultado de la busqueda no está entre los resultados del pdv  */
                await ArticuloInexistentePdv(resultado_busqueda)
                /* Error articulo inexistente, nos manenemos en la pagina con el listener abierto */
                eventBus().emitter.emit(`resultados:${elId}`, { estado: false })
                consola('Producto no existe en PDV  ')
                consola(`173 ARTICULO : ${articulo}`)

            }
            else {
                consola('en cierre sin datos')
                await CierreBuscadorSinResultados(articulo, function (estado, datos) {
                    if (estado != false) {
                        let ultimo_intento = `${datos.marca} ${datos.articulo}`
                        consola('L153:   ', elId)
                        BuscarArticulos(ultimo_intento, elId)
                    }
                })
            }
        }
    });
    modal.present();

}



/** FUNCION DE ESCANEADO  */
const scanSkuStart = async function (elId, comentario) {
    consola(comentario)
    articulo = '';
    consola(elId);
    bindLectorArticulos = scanListenerArticulos.bind(null, elId)
    consola('L61: ', elId, articulo)
    /*document.addEventListener(`keypress`, bindLectorArticulos);   */
    document.addEventListener(`keydown`, bindLectorArticulos);
    contador += 1
    consola('CONTADOR SUBE : ' + contador)
    eventBus().emitter.on(`lectura:${elId}`, async function (art_from_keyboard) {
        consola(`Articulo escaneada: verificamos lectura lectura:${elId} | ${art_from_keyboard}`)
        if (esArticulo.test(art_from_keyboard) || esEan.test(art_from_keyboard)) {
            const tipoLectura = esArticulo.test(art_from_keyboard) ? 'sku' : 'ean'
            const { status, alternativas, no_encontrados } = await ResolverLecturaLocal(art_from_keyboard, tipoLectura)
            consola('RESULTADOS ', { status, alternativas, no_encontrados })
            if (status == true && (alternativas || []).length > 0) {
                consola(`L167 Entro en Emit, ARTICULO : ${art_from_keyboard}`)
                consola(`resultados:${elId}`, { estado: true, lotes: alternativas, no_encontrados })
                eventBus().emitter.emit(`resultados:${elId}`, { estado: true, lotes: alternativas, no_encontrados })
            }
            else if (status == true && no_encontrados.length > 0) {
                /** el resultado de la busqueda no está entre los resultados del pdv  */
                await ArticuloInexistentePdv(no_encontrados)
                consola(`L221 Articulo no en articulos-pdv.  ARTICULO : ${art_from_keyboard}`)

            }
            else {
                /** no hay resultados validos, hay que buscar, s
                 * delegamos la funcion de busquedas y hacemos un return 
                 */
                consola('me he ido al buscador... ', art_from_keyboard)
                BuscarArticulos(art_from_keyboard, elId)
            }
        }
        /* 
            MONTAMOS UN FALLBACK PARA QUE SI NO ES ARTICULO NI EAN, PERO TAMPOCO UNA LECTURA HABILITADA (UBICACION O DOCUMENTO)
            DEVUELVA LA ORDEN DE PASAR EL TEXTO POR EL FILTRO DE ARTICULOS DEL PDV         
        */
        else if (every([esUbicacion, esEntrada, esSalida], t => t.test(art_from_keyboard) === false)) {
            /** 
             * Pedimos a la pagina que lance una busca sobre el stock del pdv  
             * Depuramos el articulo para asegurarnos que en el caso de las bobinas 
             * 
             * 
             * */
            let art_from_keyboard_processed = art_from_keyboard
            if (RegexBobinasPdv.test(art_from_keyboard) && art_from_keyboard.replace(RegexBobinasPdv, '').length > 2) {
                art_from_keyboard_processed = art_from_keyboard.replace(RegexBobinasPdv, '')
                consola(art_from_keyboard, art_from_keyboard_processed)
            }
            eventBus().emitter.emit(`resultados:${elId}`,
                {
                    estado: true, lotes: [], accion: 'filtrar-pdv', texto: art_from_keyboard_processed,
                    ean_sin_resultados: undefined
                })
        }

        /** HASTA AQUI LO NUEVO */
        else {
            /** Anado las líneas de paro  reninicio stop / start  */
            await scanSkuStop(elId)
            await ArticuloErroneo(art_from_keyboard)
            consola('Lectura erronea, reseteamos lector y continuamos en espera ')
            await scanSkuStart(elId)

        }
        articulo = ''
    })
}

const scanSkuStop = async function (elId) {
    articulo = '';
    try {
        document.removeEventListener(`keydown`, bindLectorArticulos);
        eventBus().emitter.off(`lectura:${elId}`)
        contador -= 1
        consola('Saliendo, contador baja : ' + contador)
        return true
    }
    catch (ex) {
        consola('Tengo un error ', ex);
        return false
    }
}



/** FUNCION DE ESCANEADO  */
const scanSkuMostradorStart = async function (elId, cliente, pdv, localhttp) {
    articulo = '';
    consola(elId);
    bindLectorArticulos = scanListenerArticulos.bind(null, elId)
    consola('L61: ', elId, articulo)
    document.addEventListener(`keydown`, bindLectorArticulos);
    contador += 1
    let skus = []
    let resultado_intermedio = []
    consola('CONTADOR SUBE : ' + contador)
    eventBus().emitter.on(`lectura:${elId}`, async function (art_from_keyboard) {
        consola(`Articulo escaneada: verificamos lectura lectura:${elId} | ${art_from_keyboard}`)
        if (esArticulo.test(art_from_keyboard) || esEan.test(art_from_keyboard)) {
            const tipoLectura = esArticulo.test(art_from_keyboard) ? 'sku' : 'ean'
            skus = tipoLectura == 'sku' ? [transformSchneiderBrand(art_from_keyboard)] : tipoLectura == 'ean' ? (eans_pdv[art_from_keyboard] || []) : []
            resultado_intermedio = stock_pdv.filter(v => (skus.indexOf(v.sku) != -1 && v.dispo || 0) > 0) || []
            window.fake_console(skus, resultado_intermedio)
            /** resultado único, hacemos que seleccione la cantidad y devolvemos el control a la pagina  */
            if (resultado_intermedio.length == 1) {
                resultado_intermedio[0].done = true;
                resultado_intermedio[0].block = true;

                /** CAMBIO 20230301 Introduzco el paro y arranque del escaner  */
                await scanSkuStop(elId)

                const elige_cantidad = await openAlertaUnidadesCarrito(cliente, pdv, resultado_intermedio[0])
                if (elige_cantidad) {
                    window.fake_console('elige cantidad ', `resultados:${elId}`, { estado: true, accion: 'add' })
                    eventBus().emitter.emit(`resultados:${elId}`, { estado: true, accion: 'add' })
                }
                /** no hacer nada, no ha elegido nada  */
                else {
                    window.fake_console('no hagas nada ', `resultados:${elId}`, { estado: true, accion: undefined })
                    eventBus().emitter.emit(`resultados:${elId}`, { estado: true, accion: undefined })
                }
                await scanSkuMostradorStart(elId, cliente, pdv, localhttp)
            }
            /** Hay más de una referencia o lote, hay que elegir  */
            else if (resultado_intermedio.length > 1) {
                window.fake_console('filtra resultados', `resultados:${elId}`, { estado: true, accion: 'filtra' })
                eventBus().emitter.emit(`resultados:${elId}`, { estado: true, termino: art_from_keyboard, accion: 'filtra' })
            }
            /** no hay ningun resultado , lo mandamos al buscador */
            else {
                window.fake_console('sigue a la busqueda ', `resultados:${elId}`, { estado: true, accion: 'busca' })
                eventBus().emitter.emit(`resultados:${elId}`, { estado: true, termino: art_from_keyboard, accion: 'busca' })

            }
        }

        /** HASTA AQUI LO NUEVO */
        else {

            const rollo_check = art_from_keyboard.toLowerCase()
            window.fake_console(rollo_check, (/rollo/ig).test(rollo_check))
            const rollo_req = await localhttp.post(`/rollos_pdv/${pdv}?numlot=${rollo_check}`)
            const rollos = rollo_req.data
            if (rollos && rollos.length > 0) {

                resultado_intermedio = rollos.map(x => {
                    const sk = x.sku
                    return Object.assign(catalogo_pdv[sk] || {}, x)
                })

                window.fake_console(resultado_intermedio)
                /** caso rollo o bobina suelta  */
                if (resultado_intermedio.length == 1) {
                    window.fake_console('ENTRO EN EL CASO 1')
                    const sku_data = resultado_intermedio[0]
                    sku_data.uds_alb = sku_data.stoalm
                    sku_data.tipo = 'stock'

                    if (cliente) {
                        await add2MostradorStorage(cliente, pdv, sku_data)
                    } else {
                        add2MostradorStorage(pdv, pdv, sku_data)
                    }
                    eventBus().emitter.emit(`resultados:${elId}`, { estado: true, accion: 'add' })
                }
                /** Caso bobina y cable  */
                else if (resultado_intermedio.length == 2 && resultado_intermedio[0].sku != resultado_intermedio[1].sku && (/rollo/ig).test(rollo_check) != true) {
                    window.fake_console('ENTRO EL CASO 2')
                    await resultado_intermedio.reduce(async (memo, v) => {
                        const m = await memo;
                        const sku_data = v
                        sku_data.uds_alb = sku_data.stoalm
                        sku_data.tipo = 'stock'
                        await add2MostradorStorage(cliente, pdv, sku_data)
                        return m
                    }, 0)
                    eventBus().emitter.emit(`resultados:${elId}`, { estado: true, accion: 'add' })
                }
                else {
                    window.fake_console('filtra resultados', `resultados:${elId}`, { estado: true, accion: 'filtra' })
                    eventBus().emitter.emit(`resultados:${elId}`, { estado: true, termino: art_from_keyboard, accion: 'filtra' })
                }


            }
            else {
                /** Añado el paro y arranque del escaner  scanSkuStop / scanSkuStop*/
                await scanSkuStop(elId)

                await ArticuloErroneo(art_from_keyboard)
                eventBus().emitter.emit(`resultados:${elId}`, { estado: false, accion: undefined })
                consola('Lectura erronea, reseteamos lector y continuamos en espera ')

                await scanSkuMostradorStart(elId, cliente, pdv, localhttp)
            }
        }
        articulo = ''
    })
}

export {
    scanSkuStart, scanSkuStop, stockPdvFiltrado, stockMostrador,
    resetResaltados, RegexBobinasPdv,
    totales, hechos, DetallesReferencia, transformSchneiderBrand,
    ActualizarUbicacionesLocales, eans_articulo, codigos_bobinas, lotes_pdv,
    DatosCatalogoSku, scanSkuMostradorStart, ArticulosLote, stock_total_con_cables
}