<template>
    <ion-col>
        <ion-card id="tarjeta" @click="routerTo()">
            <ion-card-header>
                <ion-card-title>
                    <b color="dark">{{ titulo }}</b>   
                </ion-card-title>
            </ion-card-header>
            <ion-card-content>
                {{ detalle }}
            </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>

import { IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/vue'
import { defineComponent } from 'vue'


export default defineComponent({
    name: "TarjetaHome",
    components: {
        IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent
    },
    props: [
        'link', 'titulo', 'detalle', 'inactivo', 'datos'
    ],
    mounted() {
        this.$NoBackNavigator()
    },
    methods: {
        routerTo() {
            const tipo = this.titulo.substring(0, this.titulo.indexOf(' ('))
            this.$router.replace({ 'name': this.link, params: { datos: JSON.stringify(this.datos), origen: 'home', tipo: tipo } })
        }
    }
})
</script>

<style scoped>

ion-card {
    /*margin: 0.5rem 4px !important;*/
    margin: -1.5em 0 0.5em 0 !important;
    height: 8rem !important;
    width: 100% !important;
}

ion-card-title {
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: #33a2df;
    padding: 8px 0 6px;
}

#tarjeta ion-card-header {
    padding: 0;
    margin-bottom: 10px;    
}

#tarjeta ion-card-content {
    margin: 10px;
}

</style>
