<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary" class="ion-justify-content-start ion-align-items-start">
        <ion-buttons slot="start">
          <ion-button size="default" color="light" routerLink="/home" routerDirection="none">
            <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>
          <ion-row class="ion-justify-content-start ion-align-items-start">
            <ion-col size="40" class="ion-float-left izquierda">
              Selección cliente
            </ion-col>
          </ion-row>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-header>
      <ion-toolbar color="primary" style="padding-top:8px;" class="top">
        <ion-searchbar v-model="search_manually" @ionChange="filtrar_elementos" autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="false" show-clear-button="always" :placeholder="'Buscar cliente'" slot="start"
          mode="md">
        </ion-searchbar>
      </ion-toolbar>

    </ion-header>

    <ion-content class="ion-padding">

      <ion-row v-if="lista_clientes.length > 0">
        <ion-list v-if="showme" class="ion-padding-left ion-padding-right">
          <ion-item class="resume-list" text-right>
            <i><span>{{ filtrados.length }}</span> de <span>{{ lista_clientes.length }}</span> clientes</i>
          </ion-item>

          <ion-item-sliding v-for="item in filtrados" :key="`sld-${item.cliente}`" @click="accion_cliente(item)">
            <ion-item class="item-list" :key="`cli-${item.cliente}`">
              <ion-icon no-padding no-margin :icon="item.bloqueado == 'S' ? lockClosedSharp : personCircle"
                :color="item.color" class="icon-user icon-padding">
              </ion-icon>
              <ion-label>
                <h3>{{ item.nombre }}</h3>
                <span>{{ item.cliente }}</span>
              </ion-label>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option :color="item.color" @click="void (0)">

                <ion-text class="ion-text-capitalize">
                  <div class="dentro forzar_derecha">Riesgo : {{ $filters.Entero(item.rtot) }}€ </div>
                  <div class="linea"> </div>
                  <div class="dentro forzar_derecha">
                    Disp : {{ $filters.Entero((item.bloqueado == 'S' ? 0 : item.rdispo)) }} €
                  </div>
                </ion-text>

              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-list>
      </ion-row>
      <ion-row v-else>
        <ion-row class="spin ion-justify-content-center ">
          <ion-col class="spin ion-align-self-center" size="48">
            <ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-row>
    </ion-content>
  </ion-page>
</template>
  
  
<script>


import { defineComponent } from 'vue'
import { lockClosedSharp, star, help, personCircleOutline, personCircle, homeSharp, documentTextSharp } from "ionicons/icons"
import { partition, every } from 'underscore'
import { openAlertaSiNo } from '@/utilities/services/alert-utilities'

import { get_customers_list } from '@/utilities/services/home_services'

export default defineComponent({
  name: 'CustomerListPage',
  props: ['origen'],
  data() {
    return {
      lockClosedSharp, star, help, personCircleOutline, personCircle, homeSharp, documentTextSharp,
      pdv: undefined,
      showme: false,
      filtrados: undefined,
      search_manually: undefined,
      datos: [],
      lista_clientes: [],
      limite: 50
    }
  },
  async mounted() {
    await this.fetchCustomers()
    console.log('orgien?', this.origen)
  },
  updated() {
    this.search_manually = false
    this.filtrar_elementos()
  },
  methods: {
    async fetchCustomers() {
      try {
        const dt = await get_customers_list(this)

        this.lista_clientes = dt.reduce((m, v) => {
          const kpi_riesgo = Math.ceil(v.rdispo * 100 / v.rtot)
          v['color'] = v.bloqueado == 'S' ? 'darkdanger'
            : v.rdispo <= 0 ? 'darkdanger'
              : kpi_riesgo >= 50 ? 'success'
                : kpi_riesgo >= 25 ? 'warning'
                  : 'danger'
          m.push(v)
          return m
        }, [])

        await this.filtrar_elementos()
      }
      catch (ex) {
        console.log('ex', ex)
        return null
      }
    },
    async filtrar_elementos() {
      this.showme = false
      let dat
      const terminos = this.search_manually

      if (!terminos || terminos === false) {
        dat = this.lista_clientes//.slice(0, this.limite)
      }
      else {
        const primarySearch = new RegExp(`(${terminos.toLowerCase().replace(/ +$/, '').replace(/ +/g, '.*')})`, 'i')
        const secondarySearch = terminos.toLowerCase().replace(/ +$/, '').split(' ').map(v => new RegExp(`${v}`, 'i'))
        const [primario, resto] = partition(this.lista_clientes, v => primarySearch.test(JSON.stringify(v).toLocaleLowerCase()))
        const secundario = resto.filter(v => every(secondarySearch, t => t.test(JSON.stringify(v).toLowerCase())))
        dat = primario.concat(secundario) || []
      }

      this.filtrados = dat//dat.slice(0, this.limite)
      this.showme = true
    },

    async alertaBloqueo(item) {
      return await openAlertaSiNo(item.cliente, 'Cliente bloqueado!', `
                        Parece que el cliente ${item.cliente} - ${item.nombre} esta bloqueado. <br/><br/>
                        Por favor revisa su situacion, si esta bloqueado es probable que no puedas grabar albaranes o pedidos en Gestión 2000 más tarde.<br/><br/>
                        Si quieres continuar pulsa "SI" sino "CANCELAR"`)
    },
    async accion_cliente(item) {

      let confirmacion = true
      if (item.bloqueado == 'S') {
        confirmacion = await this.alertaBloqueo(item)
      }
      if (confirmacion) {
        if (this.origen == 'home') {
          this.$router.replace({ 'name': 'home_cliente', params: { 'cliente': item.cliente, 'origen': 'home' } })
        }
        else if (this.origen == 'carrito') {
          console.log('volvemos al carrito pero con cliente', item.cliente)
          this.$router.replace({ 'name': 'carrito', params: { 'cliente': item.cliente, 'nomcli': item.nombre, 'origen': 'lista_clientes' } })
        }
      }
    }
  }
})
</script>
  

   
<style scoped lang="scss">
ion-text {
  height: 100% !important;
}

div.dentro {
  margin: 2% 0% !important;
  height: 50% !important;

}

.linea {
  width: 100% !important;
  height: 55% !important;
  border-bottom: 2px solid;
  /*position: relative !important;
      top: 0px !important; 
      left: 0px !important; */
  z-index: 9999 !important;
}

div.forzar_derecha {
  float: right !important;
  display: inline;
  /*direction: rtl !important;*/
  float: right !important;
  text-align: right !important;
  white-space: nowrap !important;
  overflow: visible !important;
}

h3,
h4,
h5,
p {
  font-size: smaller !important;
  margin-top: 3px !important;
  /*margin-bottom: 3px !important;*/
}

ion-searchbar {
  width: 100% !important;
  margin-left: 6px !important;
}

ion-toolbar.top {
  margin-bottom: -0.4rem !important;
  padding-bottom: -0.4rem !important;
}

ion-title {
  width: 100% !important;
  padding-right: 4px;
}

.izquierda {
  text-align: left;
}


ion-row {
  max-width: 600px !important;
  padding: 0.5rem 1rem !important;
}

ion-item,
ion-list {
  width: 100% !important;
  margin: auto !important;
}

ion-list-header {
  color: var(--ion-color-primary) !important;
  border-bottom: solid 1px var(--ion-color-light) !important;
  padding-bottom: 4px !important;
}

.after-header {
  background-color: #ff0000 !important;
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
}

.search-list {
  padding-top: 0;
}

.resume-list i {
  font-size: 12px;
}

.resume-list span {
  font-size: 14px;
}

ion-label h3 {
  font-weight: bolder;
  color: #203360;
}

ion-label span {
  font-size: 12px;
}

.item-list {
  margin-right: 25px;
}

.icon-user {
  font-size: 30px;
}

.candado {
  position: absolute;
  top: 10px;
  font-size: 12px;
  left: 15px;
  background: #FFF;
  border-radius: 20px;
  padding: 2px;
  border: 1px solid #EB445A;
}


ion-toolbar {
  padding: 0 5px;
}

.icon-padding {
  margin-right: 13px;
}

.locked-white {
  color: #fff;
}

.candado {
  margin-right: 5px;
}

.item-list:hover {
  --background-hover: gray;
}

@media (max-width: 900px) {
  ion-grid {
    width: 100%;
  }

  ion-toolbar {
    margin: 0;
    width: 100%;
  }

  ion-toolbar {
    padding: 0 10px !important;
  }

  ion-searchbar {
    padding: 0;
  }

  ion-button {
    font-size: 12px;
  }

  .title-customer-list {
    font-size: 15px;
    padding-inline-start: 50px;
    padding-inline-end: 10px;
    text-align: left;
  }
}
</style>