let data_reporting

const get_reporting_data = async (self, tipo) => {
    let data = await self.$http.post(`/rac_reporting`, { tipo: tipo })

    data_reporting = data.data[0].datos
    return data_reporting
}


export {
    get_reporting_data,
    data_reporting
}