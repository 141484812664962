import { removeStorage, saveStorage, loadStorage, saveHomeStorage } from '@/utilities/storage/storage-utilities-main'

const get_ce_data = async function (self) {
    try {
        const t = await loadStorage('authorization')

        var ceData = undefined

        self.$http.defaults.headers['Authorization'] = 'Bearer ' + t
        var supRequest = await self.$http.post(`/home`, { totales: 'S'})
        var res = supRequest.data

        if (res) {
            ceData = res
            await saveHomeStorage(res.clientes, res.pedidos, res.ofertas)
        }
 
        supRequest = await self.$http.post(`/my_sales_network`)
        res = supRequest.data

        await saveStorage('mysales_network', res)
    
        if (ceData === undefined) {
            ceData = res            
        } else {
            ceData['red_comercial'] = res
        }
        return ceData
    }
    catch (error) {
        window.fake_console('EN EL ERROR DE LOGIN')
        window.fake_console(error)
        console.log(error)
        console.log('Login 73 (Error) : ', JSON.stringify(error))
    }
}

const emularUsuario = async function (self) {
    const supRequest = await self.$http.post("/change_user/", {
        dev: self.$deviceUuid,
        persona: self.emulado
    })
    const res = supRequest.data    
    if (res.status) {
        await saveStorage('emulated_user', self.emulado)
    }
}

const deshacerEmulacion = async function (self) {
    const supRequest = await self.$http.post("/change_user/", {
        dev: self.$deviceUuid
    })
    const res = supRequest.data
    if (res.status) {
        await removeStorage('emulated_user')
        await get_ce_data(self)
    }
}

const get_customers_list = async function (self) {
    const supRequest = await self.$http.post(`/get_clientes_pdv`)
    return supRequest.data
}

const get_customer_docs = async function (self, ruta, cliente) {
    console.log('a ver que recupero de ', ruta)
    const supRequest = await self.$http.post(ruta, { cliente: cliente })
    console.log('home_services supRequest', supRequest)
    return supRequest.data
}


const get_cartera = async function (self) {
    const usr = self.$emulated_user || self.$current_user
    console.log(`await self.$http.get(/cartera/${usr})`)
    const supRequest = await self.$http.get(`/cartera/${usr}`)
    return JSON.parse(supRequest.data[0].datos)

}

export {
    get_ce_data,    
    emularUsuario,
    deshacerEmulacion,
    get_customers_list,
    get_customer_docs,
    get_cartera
}